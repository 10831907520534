<template>
  <div class="page-view-stock">

    <p class="content"><router-link to="/stock" class="button is-outlined is-primary">Back to stock</router-link></p>

    <div v-if="game">

      <h1 class="title is-4">{{ game.title }}</h1>

      <div class="well">
        <a v-bind:href="sellerApi.formatImageUrl(image.filename)" target="_blank" v-for="image in game.images" v-bind:key="image.id">
          <img v-bind:src="sellerApi.formatThumbnailImageUrl(image.filename)" v-bind:alt="image.filename" class="image">
        </a>
      </div>

      <p class="content">Stock status: <strong>{{ formatStatus(game.status) }}</strong></p>
      <p class="content">
        <button class="button is-outlined is-info" v-on:click.prevent="setStatus(1)">Set status to "For Sale"</button>&nbsp;
        <button class="button is-outlined is-success" v-on:click.prevent="setStatus(0)">Set status to "Sold"</button>&nbsp;
        <button class="button is-outlined is-danger" v-on:click.prevent="deleteGame">Delete</button>
      </p>

      <form v-on:submit.prevent="saveChanges">

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            {{ game.id }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Date</label>
          </div>
          <div class="field-body">
            {{ game.created_at }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Platform</label>
          </div>
          <div class="field-body">
            <div class="select">
              <select v-model="game.platform">
                <option value="3DO">3DO</option>
                <option value="Amiga">Amiga</option>
                <option value="Amiga CD32">Amiga CD32</option>
                <option value="Amiibo">Amiibo</option>
                <option value="Arcadia 2001">Arcadia 2001</option>
                <option value="Atari 2600">Atari 2600</option>
                <option value="Atari 400">Atari 400</option>
                <option value="Atari 5200">Atari 5200</option>
                <option value="Atari 7800">Atari 7800</option>
                <option value="Atari Lynx">Atari Lynx</option>
                <option value="Atari ST">Atari ST</option>
                <option value="Bally Astrocade">Bally Astrocade</option>
                <option value="CD-i">CD-i</option>
                <option value="Colecovision">Colecovision</option>
                <option value="Commodore 64">Commodore 64</option>
                <option value="Disney Infinity">Disney Infinity</option>
                <option value="Fairchild Channel F">Fairchild Channel F</option>
                <option value="Famicom">Famicom</option>
                <option value="Famicom Disk System">Famicom Disk System</option>
                <option value="Game & Watch">Game & Watch</option>
                <option value="Game.Com">Game.Com</option>
                <option value="GameBoy">GameBoy</option>
                <option value="GameBoy Advance">GameBoy Advance</option>
                <option value="GameBoy Color">GameBoy Color</option>
                <option value="Gamecube">Gamecube</option>
                <option value="Gizmondo">Gizmondo</option>
                <option value="Intellivision">Intellivision</option>
                <option value="Jaguar">Jaguar</option>
                <option value="Lego Dimensions">Lego Dimensions</option>
                <option value="Magnavox Odyssey">Magnavox Odyssey</option>
                <option value="Magnavox Odyssey 2">Magnavox Odyssey 2</option>
                <option value="Magnavox Odyssey 300">Magnavox Odyssey 300</option>
                <option value="Mattel Aquarius">Mattel Aquarius</option>
                <option value="Microvision">Microvision</option>
                <option value="Mini Arcade">Mini Arcade</option>
                <option value="N-Gage">N-Gage</option>
                <option value="NES">NES</option>
                <option value="Neo Geo">Neo Geo</option>
                <option value="Neo Geo AES">Neo Geo AES</option>
                <option value="Neo Geo CD">Neo Geo CD</option>
                <option value="Neo Geo Pocket Color">Neo Geo Pocket Color</option>
                <option value="Nintendo 3DS">Nintendo 3DS</option>
                <option value="Nintendo 64">Nintendo 64</option>
                <option value="Nintendo DS">Nintendo DS</option>
                <option value="Nintendo Power">Nintendo Power</option>
                <option value="Nintendo Switch">Nintendo Switch</option>
                <option value="PAL Amiga CD32">PAL Amiga CD32</option>
                <option value="PC FX">PC FX</option>
                <option value="PC">PC</option>
                <option value="PSP">PSP</option>
                <option value="Playstation">Playstation</option>
                <option value="Playstation 2">Playstation 2</option>
                <option value="Playstation 3">Playstation 3</option>
                <option value="Playstation 4">Playstation 4</option>
                <option value="Playstation 5">Playstation 5</option>
                <option value="Playstation Vita">Playstation Vita</option>
                <option value="Pokemon Mini">Pokemon Mini</option>
                <option value="Sega 32X">Sega 32X</option>
                <option value="Sega CD">Sega CD</option>
                <option value="Sega Dreamcast">Sega Dreamcast</option>
                <option value="Sega Game Gear">Sega Game Gear</option>
                <option value="Sega Genesis">Sega Genesis</option>
                <option value="Sega Master System">Sega Master System</option>
                <option value="Sega Pico">Sega Pico</option>
                <option value="Sega Saturn">Sega Saturn</option>
                <option value="Skylanders">Skylanders</option>
                <option value="Super CD-ROM">Super CD-ROM</option>
                <option value="Super Famicom">Super Famicom</option>
                <option value="Super Nintendo">Super Nintendo</option>
                <option value="TI-99">TI-99</option>
                <option value="TurboGrafx-16">TurboGrafx-16</option>
                <option value="Vectrex">Vectrex</option>
                <option value="Vic-20">Vic-20</option>
                <option value="Videopac G7000">Videopac G7000</option>
                <option value="Videopac G7400">Videopac G7400</option>
                <option value="Virtual Boy">Virtual Boy</option>
                <option value="Wii">Wii</option>
                <option value="Wii U">Wii U</option>
                <option value="WonderSwan">WonderSwan</option>
                <option value="WonderSwan Color">WonderSwan Color</option>
                <option value="Xbox">Xbox</option>
                <option value="Xbox 360">Xbox 360</option>
                <option value="Xbox One">Xbox One</option>
                <option value="Xbox Series X">Xbox Series X</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Region</label>
          </div>
          <div class="field-body">
            <div class="select">
              <select v-model="game.region">
                <option value="REGION_FREE">Region-free</option>
                <option value="PAL">PAL</option>
                <option value="NTSC">NTSC</option>
                <option value="NTSC-J">NTSC-J</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Condition</label>
          </div>
          <div class="field-body">
            <div class="select">
              <select v-model="game.condition">
                <option value="New">New</option>
                <option value="Very good">Very good</option>
                <option value="Good">Good</option>
                <option value="Acceptable">Acceptable</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Type</label>
          </div>
          <div class="field-body">
            <div class="select">
              <select v-model="game.type">
                <option value="Loose">Loose</option>
                <option value="CIB">CIB</option>
                <option value="New">New</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Description</label>
          </div>
          <div class="field-body">
            <textarea class="textarea" v-model="game.description"  placeholder="Description text..."></textarea>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Price (EUR)</label>
          </div>
          <div class="field-body">
            <input class="input" type="number" v-model="game.price" placeholder="4.90" step="0.1" min="0.1" >
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label"></label>
          </div>
          <div class="field-body">
            <price-suggestions v-bind:search-object="game" v-on:price="updatePrice"></price-suggestions>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label"></label>
          </div>
          <div class="field-body">
            <input type="submit" value="Save" class="button is-primary">
          </div>
        </div>

      </form>

      <br/>
      <br/>

      <p class="content">
        <router-link v-bind:to="'/list/ebay/' + game.id" class="button is-info">List in Ebay</router-link>
        &nbsp;
        <router-link v-bind:to="'/list/huutonet/' + game.id" class="button is-info">List in Huuto.net</router-link>
        &nbsp;
        <router-link v-bind:to="'/list/retroplace/' + game.id" class="button is-info">List in Retroplace</router-link>
      </p>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Type</th>
            <th>ID</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="game.listings.length === 0">
            <td colspan="5">No listings</td>
          </tr>
          <tr v-else v-for="listing in game.listings" v-bind:key="listing.id">
            <td>{{ listing.type }}</td>
            <td><a v-bind:href="formatListingUrl(listing)" target="_blank">{{ listing.external_id }}</a></td>
            <td>{{ listing.created_at }}</td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>

import { sellerApi } from '@/sellerApi';
import PriceSuggestions from "@/components/PriceSuggestions";

export default {
  name: 'ViewStock',
  data: function() {
    return {
      game: false,
      sellerApi
    }
  },
  components: {
    PriceSuggestions
  },
  mounted: function() {
    var component = this;

    sellerApi.client.get("stock/" + this.$route.params.id)
        .then(function(response) {
          component.game = response.data;
        })
        .catch(function(error) {
          alert(error);
        });
  },
  methods: {
    formatListingUrl: function(listing) {
      switch(listing.type) {
        case 'ebay':
          return "https://www.ebay.com/itm/game/" + listing.external_id;
        case 'huutonet':
          return "https://huuto.net/kohteet/game/" + listing.external_id;
        case 'retroplace':
          return "https://www.retroplace.com/en/games/" + listing.external_id;
      }
    },
    formatStatus: function(status) {
      switch(status) {
        case 0:
          return 'Sold';
        case 1:
          return 'For Sale';
      }
    },
    setStatus: function(status) {
      var component = this;

      sellerApi.client.post("stock/" + this.$route.params.id + "/status/" + status)
          .then(function(response) {
            component.game = response.data;
          })
          .catch(function(error) {
            alert(error);
          });
    },
    saveChanges: function() {
      var component = this;

      sellerApi.client.post("stock/" + this.$route.params.id + "/edit", {
        platform: this.game.platform,
        region: this.game.region,
        type: this.game.type,
        condition: this.game.condition,
        description: this.game.description,
        price: this.game.price
      })
          .then(function() {
            // Redirect back to stock
            component.$router.push('/stock');
          })
          .catch(function(error) {
            alert(error);
          });
    },
    deleteGame: function() {
      var component = this;

      sellerApi.client.post("stock/" + this.$route.params.id + "/delete")
          .then(function() {
            component.$router.push('/stock');
          })
          .catch(function(error) {
            alert(error);
          });
    },
    updatePrice: function(usdPrice) {
      this.game.price = Math.round(usdPrice / 1.2) + 0.90;
    }
  }
}
</script>

<style lang="scss">

.page-view-stock {
  .well .image {
    max-width: 15rem;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    vertical-align: top;
  }
}

</style>
