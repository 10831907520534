<template>
  <div class="page-list-ebay">

    <div v-if="game">

      <h1 class="title is-4">List {{ game.title }} for sale in eBay</h1>

      <form>

        <div class="field">
          <label class="label">Title</label>
          <div class="control">
            <input type="text" v-bind:value="title" class="input" onclick="this.select();">
          </div>
        </div>

        <div class="field">
          <label class="label">Condition</label>
          <div class="control">
            {{ game.condition }}
          </div>
        </div>

        <div class="columns">
          <div class="column is-one-fifth" v-for="image in game.images" v-bind:key="image.id">
            <a v-bind:href="sellerApi.formatImageUrl(image.filename)" target="_blank" >
              <img v-bind:src="sellerApi.formatThumbnailImageUrl(image.filename)" v-bind:alt="image.filename" class="image"><br/>
            </a>
            <input type="text" v-bind:value="sellerApi.formatImageUrl(image.filename)"  class="input" onclick="this.select();">
          </div>
        </div>

        <div class="field">
          <label class="label">Description</label>
          <div class="control">
            <input type="text" v-bind:value="game.description" class="input" onclick="this.select();">
          </div>
        </div>

        <div class="field">
          <label class="label">Price (USD)</label>
          <div class="control">
            <input type="number" v-bind:value="price" class="input" onclick="this.select();" step="0.1" min="0.1" >
          </div>
        </div>

        <div class="field">
          <label class="label">Price suggestions</label>
          <div class="control">
            <price-suggestions v-bind:search-object="this.game" v-on:price="updatePrice"></price-suggestions>
          </div>
        </div>

        <div class="field">
          <label class="label">Ebay ID</label>
          <div class="control">
            <input type="text" class="input" v-model="ebayId">
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="button is-primary" v-on:click.prevent="submit">Submit</button>&nbsp;
            <button class="button is-warning" v-on:click.prevent="goToNextPage">Skip</button>
          </div>
        </div>

        <p class="content">Ids in queue: {{ idList.length - 1 }}</p>

        <div v-if="submitting" class="notification is-warning">
          Submitting listing, please wait...
        </div>

      </form>

    </div>

  </div>
</template>

<script>
import { sellerApi } from '@/sellerApi';
import PriceSuggestions from "@/components/PriceSuggestions";

export default {
  name: 'ListEbay',
  components: {
    PriceSuggestions
  },
  data: function() {
    return {
      game: false,
      title: "",
      ebayId: "",
      price: 0,
      activeId: 0,
      idList: [],
      submitting: false,
      sellerApi
    }
  },
  mounted: function() {
    this.idList = this.$route.params.id.split(",");
    this.activeId = this.idList[0];
    this.loadData();
  },
  methods: {
    loadData: function() {
      var component = this;

      sellerApi.client.get("stock/" + component.activeId)
          .then(function(response) {
            component.game = response.data;
            // Ebay platform matching for PS platforms
            if(component.game.platform.includes("Playstation")) {
              component.game.platform = component.game.platform.replace("Playstation ", "PS");
            }
            component.title = component.game.title + " (" + component.game.platform + (component.game.region !== "REGION_FREE" ? " " + component.game.region : "" ) + ") (" + component.game.type + ")";
            // Suggested price in USD
            component.price = Math.round(component.game.price * 1.2) + 0.99;
          })
          .catch(function(error) {
            alert(error);
          });
    },
    submit: function() {
      var component = this;
      this.submitting = true;
      sellerApi.client.post("list/ebay", {
        id: this.game.id,
        ebay_id: this.ebayId
      })
          .then(function(response) {
            component.submitting = false;
            if(response.data.status === "ok") {
              component.goToNextPage();
            }
          })
          .catch(function(error) {
            component.submitting = false;
            alert(error);
          });
    },
    updatePrice: function(usdPrice) {
      this.price = Math.round(usdPrice) + 0.99;
    },
    goToNextPage: function() {
      // Get next id in queue
      this.idList = this.idList.slice(1);
      if(this.idList.length === 0) {
        this.$router.push("/stock");
      } else {
        // Update url (only history)
        this.$router.push("/list/ebay/" + this.idList.join(","));
        window.scrollTo({top: 0, left: 0});
        this.activeId = this.idList[0];
        this.ebayId = "";
        this.loadData();
      }
    }
  }
}
</script>

<style lang="scss">

.page-list-ebay {
  .well .image {
    max-width: 15rem;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    vertical-align: top;
  }
}

</style>
