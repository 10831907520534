<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" exact active-class="is-active" class="navbar-item">
        Video Games
      </router-link>

      <a role="button" class="navbar-burger" v-bind:class="{ 'is-active': navbarActive }" aria-label="menu" aria-expanded="false" v-on:click="navbarActive = !navbarActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbar" class="navbar-menu" v-bind:class="{ 'is-active': navbarActive }">
      <div class="navbar-start">
        <router-link to="/" exact active-class="is-active" class="navbar-item">
          <i class="fas fa-dollar-sign"></i> &nbsp;Check Price
        </router-link>

        <router-link to="/stock" active-class="is-active" class="navbar-item">
          <i class="fas fa-cubes"></i> &nbsp;Stock
        </router-link>

        <router-link to="/listings" active-class="is-active" class="navbar-item">
          <i class="fas fa-copy"></i> &nbsp;Listings
        </router-link>

        <router-link to="/deals" active-class="is-active" class="navbar-item">
          <i class="fas fa-money-bill"></i> &nbsp;Deals
        </router-link>
      </div>
    </div>
  </nav>
  <div class="container">
    <router-view/>
  </div>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        VG Seller
      </p>
    </div>
  </footer>
</template>

<style lang="scss">

@import "../node_modules/bulma/bulma";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

</style>

<script>

export default {
  name: 'App',
  data: function() {
    return {
      navbarActive: false
    };
  }
}

</script>
