<template>
  <div class="page-new-stock">

    <div class="notification is-success" v-if="successMessage">
      <button class="delete" v-on:click="successMessage = ''"></button>
      {{ successMessage }}
    </div>

    <h2 class="title is-4">Scan EAN</h2>

    <form v-on:submit.prevent="doSearch">
      <input type="text" class="input" v-model="eanInput" placeholder="Search by EAN code...">

      <input type="submit" class="button is-primary" value="Search">
    </form>

    <p class="tags">Got these items: <span v-for="(game, index) in gameSuggestions" class="tag is-clickable" v-bind:key="game.id" v-on:click="selectSuggestion(index)">{{ game.name }} ({{ game.console }})</span></p>

    <h2 class="title is-4">Or input manually</h2>

    <form v-on:submit.prevent="submit">

      <div class="field">
        <label class="label">Title</label>
        <div class="control">
          <input class="input" type="text" v-model="title" placeholder="Title of game">
        </div>
      </div>

      <div id="dropzone" class="dropzone"></div>

      <div class="field">
        <label class="label">Platform</label>
        <div class="control">
          <div class="select">
            <select v-model="platform">
              <option value="3DO">3DO</option>
              <option value="Amiga">Amiga</option>
              <option value="Amiga CD32">Amiga CD32</option>
              <option value="Amiibo">Amiibo</option>
              <option value="Arcadia 2001">Arcadia 2001</option>
              <option value="Atari 2600">Atari 2600</option>
              <option value="Atari 400">Atari 400</option>
              <option value="Atari 5200">Atari 5200</option>
              <option value="Atari 7800">Atari 7800</option>
              <option value="Atari Lynx">Atari Lynx</option>
              <option value="Atari ST">Atari ST</option>
              <option value="Bally Astrocade">Bally Astrocade</option>
              <option value="CD-i">CD-i</option>
              <option value="Colecovision">Colecovision</option>
              <option value="Commodore 64">Commodore 64</option>
              <option value="Disney Infinity">Disney Infinity</option>
              <option value="Fairchild Channel F">Fairchild Channel F</option>
              <option value="Famicom">Famicom</option>
              <option value="Famicom Disk System">Famicom Disk System</option>
              <option value="Game & Watch">Game & Watch</option>
              <option value="Game.Com">Game.Com</option>
              <option value="GameBoy">GameBoy</option>
              <option value="GameBoy Advance">GameBoy Advance</option>
              <option value="GameBoy Color">GameBoy Color</option>
              <option value="Gamecube">Gamecube</option>
              <option value="Gizmondo">Gizmondo</option>
              <option value="Intellivision">Intellivision</option>
              <option value="Jaguar">Jaguar</option>
              <option value="Lego Dimensions">Lego Dimensions</option>
              <option value="Magnavox Odyssey">Magnavox Odyssey</option>
              <option value="Magnavox Odyssey 2">Magnavox Odyssey 2</option>
              <option value="Magnavox Odyssey 300">Magnavox Odyssey 300</option>
              <option value="Mattel Aquarius">Mattel Aquarius</option>
              <option value="Microvision">Microvision</option>
              <option value="Mini Arcade">Mini Arcade</option>
              <option value="N-Gage">N-Gage</option>
              <option value="NES">NES</option>
              <option value="Neo Geo">Neo Geo</option>
              <option value="Neo Geo AES">Neo Geo AES</option>
              <option value="Neo Geo CD">Neo Geo CD</option>
              <option value="Neo Geo Pocket Color">Neo Geo Pocket Color</option>
              <option value="Nintendo 3DS">Nintendo 3DS</option>
              <option value="Nintendo 64">Nintendo 64</option>
              <option value="Nintendo DS">Nintendo DS</option>
              <option value="Nintendo Power">Nintendo Power</option>
              <option value="Nintendo Switch">Nintendo Switch</option>
              <option value="PAL Amiga CD32">PAL Amiga CD32</option>
              <option value="PC FX">PC FX</option>
              <option value="PC">PC</option>
              <option value="PSP">PSP</option>
              <option value="Playstation">Playstation</option>
              <option value="Playstation 2">Playstation 2</option>
              <option value="Playstation 3">Playstation 3</option>
              <option value="Playstation 4">Playstation 4</option>
              <option value="Playstation 5">Playstation 5</option>
              <option value="Playstation Vita">Playstation Vita</option>
              <option value="Pokemon Mini">Pokemon Mini</option>
              <option value="Sega 32X">Sega 32X</option>
              <option value="Sega CD">Sega CD</option>
              <option value="Sega Dreamcast">Sega Dreamcast</option>
              <option value="Sega Game Gear">Sega Game Gear</option>
              <option value="Sega Genesis">Sega Genesis</option>
              <option value="Sega Master System">Sega Master System</option>
              <option value="Sega Pico">Sega Pico</option>
              <option value="Sega Saturn">Sega Saturn</option>
              <option value="Skylanders">Skylanders</option>
              <option value="Super CD-ROM">Super CD-ROM</option>
              <option value="Super Famicom">Super Famicom</option>
              <option value="Super Nintendo">Super Nintendo</option>
              <option value="TI-99">TI-99</option>
              <option value="TurboGrafx-16">TurboGrafx-16</option>
              <option value="Vectrex">Vectrex</option>
              <option value="Vic-20">Vic-20</option>
              <option value="Videopac G7000">Videopac G7000</option>
              <option value="Videopac G7400">Videopac G7400</option>
              <option value="Virtual Boy">Virtual Boy</option>
              <option value="Wii">Wii</option>
              <option value="Wii U">Wii U</option>
              <option value="WonderSwan">WonderSwan</option>
              <option value="WonderSwan Color">WonderSwan Color</option>
              <option value="Xbox">Xbox</option>
              <option value="Xbox 360">Xbox 360</option>
              <option value="Xbox One">Xbox One</option>
              <option value="Xbox Series X">Xbox Series X</option>
            </select>
          </div>
        </div>
      </div>

      <div class="columns">

        <div class="column">

          <div class="field">
            <label class="label">Region</label>
            <div class="control">
              <div class="select">
                <select v-model="region">
                  <option value="REGION_FREE">Region-free</option>
                  <option value="PAL">PAL</option>
                  <option value="NTSC">NTSC</option>
                  <option value="NTSC-J">NTSC-J</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="column">

          <div class="field">
            <label class="label">Type</label>
            <div class="control">
              <div class="select">
                <select v-model="type">
                  <option value="Loose">Loose</option>
                  <option value="CIB">CIB</option>
                  <option value="New">New</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="column">

          <div class="field">
            <label class="label">Condition</label>
            <div class="control">
              <div class="select">
                <select v-model="condition">
                  <option value="New">New</option>
                  <option value="Very good">Very good</option>
                  <option value="Good">Good</option>
                  <option value="Acceptable">Acceptable</option>
                </select>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <div class="tags">
            <span class="tag" v-for="(description, key) in descriptions" v-bind:key="key" v-on:click="addTag">{{ description.en }}</span>
          </div>
          <textarea class="textarea" v-model="description"  placeholder="Description text..."></textarea>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button class="button is-primary">Submit</button>
        </div>
      </div>

    </form>

  </div>
</template>

<script>
import { sellerApi } from '@/sellerApi';
import Dropzone from 'dropzone';

import { productDescriptions } from '@/productDescriptions';

Dropzone.autoDiscover = false;

export default {
  name: 'NewStock',
  data: function() {
    return {
      title: "",
      platform: "Playstation 2",
      region: "PAL",
      type: "CIB",
      condition: "Very good",
      description: "",
      dropzone: false,
      eanInput: "",
      gameSuggestions: [],
      successMessage: "",
      descriptions: productDescriptions
    }
  },
  mounted: function() {
    this.dropzone = new Dropzone("div#dropzone", {
      url: sellerApi.baseUrl + "stock/new",
      uploadMultiple: true,
      paramName: "images",
      acceptedFiles: "image/*",
      autoProcessQueue: false,
      capture: "camera",
      addRemoveLinks: true
    });
  },
  methods: {
    addTag: function(event) {
      this.description = this.description + event.target.innerHTML + ". ";
    },
    submit: function() {
      var component = this;
      var images = [];

      this.dropzone.getQueuedFiles().forEach(function(image) {
        images.push(image.dataURL);
      });

      sellerApi.client.post("stock/new", {
        title: this.title,
        platform: this.platform,
        region: this.region,
        type: this.type,
        condition: this.condition,
        description: this.description,
        images: images
      })
      .then(function(response) {
        if(response.data.status === "ok") {
          component.successMessage = "Game '" + component.title + "' added successfully.";
          component.title = "";
          component.description = "";
          component.dropzone.removeAllFiles();
          component.gameSuggestions = [];
          component.eanInput = "";
          window.scrollTo(0, 0);
        }
      })
      .catch(function(error) {
        alert(error);
      });

    },
    doSearch: function() {
      var component = this;
      sellerApi.client.get("search/ean/" + this.eanInput)
          .then(function(response) {
            if(response.data.status === "ok") {
              component.gameSuggestions = response.data.priceCharting;
              component.$nextTick(function () {
                component.selectSuggestion(0);
              });
            } else if(response.data.status === "error") {
              alert(response.data.message);
            }
          })
          .catch(function(error) {
            alert(error);
          });
    },
    selectSuggestion: function(index) {
      this.title = this.gameSuggestions[index].name;
      this.platform = this.gameSuggestions[index].console.replace("PAL ", "").replace("JP ", "");
      if(this.gameSuggestions[index].console.substr(0, 4) === "PAL ") {
        this.region = "PAL";
      } else if(this.gameSuggestions[index].console.substr(0, 3) === "JP ") {
        this.region = "NTSC-J";
      }
    }
  }
}
</script>

<style lang="scss">

@import "../../node_modules/dropzone/dist/dropzone.css";

.page-new-stock {
  .tags .tag:hover {
    cursor: pointer;
    background: #999;
  }
}

</style>
