<template>
  <div class="page-deals">

    <div class="field">
      <label class="label">Score (0 to 2000)</label>
      <div class="control">
        <input class="input" type="text" v-model.lazy="scoreLimit">
      </div>
    </div>

    <table class="table" id="deals-table"></table>

  </div>
</template>

<script>

import { DataTable } from 'simple-datatables';

import { sellerApi } from '@/sellerApi';

export default {
  name: 'Deals',
  data: function() {
    return {
      dataTable: false,
      scoreLimit: 1500
    }
  },
  mounted: function() {
    this.initDataTable();
  },
  watch: {
    scoreLimit: function() {
      this.initDataTable();
    }
  },
  methods: {
    initDataTable: function() {

      var component = this;

      if (component.dataTable) {
        component.dataTable.destroy();
      }

      sellerApi.client.get("deal/all/" + component.scoreLimit)
          .then(function (response) {

            var listings = [];
            response.data.forEach(function (listing) {
              listings.push([
                listing.type,
                listing.external_id,
                listing.title,
                listing.price.toString(),
                listing.pricecharting_title,
                listing.pricecharting_loose + "<br/>" + listing.pricecharting_cib + "<br/>" + listing.pricecharting_new,
                listing.profit.toString(),
                listing.profit_pct.toString(),
                "<a href='/deals/" + listing.id + "' class='button'>View / Edit</a>"
              ]);
            });

            component.dataTable = new DataTable("#deals-table", {
              columns: [
                {
                  select: 2,
                  render: function (data, cell, row) {
                    switch (row.cells[0].innerText) {
                      case 'ebay':
                        return "<a href='https://www.ebay.com/itm/game/" + row.cells[1].innerText + "' target='_blank'>" + cell.innerText + "</a>";
                      case 'huutonet':
                        return "<a href='https://huuto.net/kohteet/game/" + row.cells[1].innerText + "' target='_blank'>" + cell.innerText + "</a>";
                    }
                  }
                }
              ],
              data: {
                headings: [
                  "Type",
                  "ID",
                  "Title",
                  "Price",
                  "PC Title",
                  "PC Prices",
                  "Profit",
                  "Profit %",
                  "Actions"
                ],
                data: listings
              }
            });

          });

    }
  }
}
</script>

<style lang="scss">

@import "../../node_modules/simple-datatables/src/style.css";

</style>
