import axios from 'axios';

var baseUrl = 'https://vg-seller.caprover.polso.info/api/';

export const sellerApi = {
    baseUrl: baseUrl,
    client: axios.create({
        baseURL: baseUrl
    }),
    formatImageUrl: function(filename) {
        return this.baseUrl + 'stock/image/' + filename;
    },
    formatThumbnailImageUrl: function(filename) {
        return this.baseUrl + 'stock/thumbnail_image/' + filename;
    }
};
