<template>
  <div class="page-list-retroplace">

    <div v-if="game">

      <h1 class="title is-4">List {{ game.title }} ({{ game.region }}) for sale in Retroplace</h1>

      <form>

        <div class="field">
          <label class="label">Search for Retroplace ID</label>
          <div class="control">
            <input class="input" type="text" v-model="retroplaceSearch"><br/>
            <button class="button is-info is-outlined" v-on:click.prevent="getRetroplaceItems">Search Retroplace</button>
            <div class="well" v-if="retroplaceSuggestions.length">
              <button class="button is-info is-small is-outlined is-block" v-for="suggestion in retroplaceSuggestions" v-bind:key="suggestion.id" v-bind:class="{'is-focused': retroplaceId === suggestion.id }" v-on:click.prevent="getRetroplaceItems(suggestion)">{{ suggestion.id }} | {{ suggestion.text }} | {{ suggestion.system }}</button>
            </div>
            <div class="well" v-if="retroplaceItemSuggestions.length">
              <button class="button is-danger is-small is-outlined is-block" v-for="suggestion in retroplaceItemSuggestions" v-bind:key="suggestion.id" v-bind:class="{'is-focused': retroplaceId === suggestion.id }" v-on:click.prevent="retroplaceId = suggestion.id">{{ suggestion.title }}</button>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Price suggestions</label>
          <div class="control">
            <price-suggestions v-bind:search-object="this.game" v-on:price="updatePrice"></price-suggestions>
          </div>
        </div>

        <div class="field">
          <label class="label">Price (EUR)</label>
          <div class="control">
            <input class="input" type="number" v-model="price" placeholder="4.90" step="0.1" min="0.1" >
          </div>
        </div>

        <div class="field">
          <label class="label">Condition</label>
          <div class="control">
            <div class="select">
              <select v-model="condition">
                <option value="new">New</option>
                <option value="perfect">Like new</option>
                <option value="very_good">Very good (has to incl. manual)</option>
                <option value="good">Good</option>
                <option value="acceptable">Acceptable</option>
                <option value="broken">Broken</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Components</label>
          <div class="control">
            <span>Type: <strong>{{ game.type }}</strong></span>
            <br/>
            <label class="checkbox">
              <input type="checkbox" v-model="components" value="media">
              Media
            </label>
            <br/>
            <label class="checkbox">
              <input type="checkbox" v-model="components" value="box">
              Box
            </label>
            <br/>
            <label class="checkbox">
              <input type="checkbox" v-model="components" value="manual">
              Manual
            </label>
          </div>
        </div>

        <div class="field">
          <label class="label">Description</label>
          <div class="control">
            <textarea class="textarea" v-model="description"  placeholder="Description text..."></textarea>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="button is-primary" v-on:click.prevent="submit">Submit</button>&nbsp;
            <button class="button is-warning" v-on:click.prevent="goToNextPage">Skip</button>
          </div>
        </div>

        <p class="content">Ids in queue: {{ idList.length - 1 }}</p>

        <div v-if="submitting" class="notification is-warning">
          Submitting listing, please wait...
        </div>

      </form>

    </div>

  </div>
</template>

<script>
import { sellerApi } from '@/sellerApi';
import PriceSuggestions from "@/components/PriceSuggestions";

export default {
  name: 'ListRetroplace',
  components: {
    PriceSuggestions
  },
  data: function() {
    return {
      game: false,
      title: "",
      price: 4.90,
      condition: "good",
      submitting: false,
      retroplaceId: "",
      retroplaceSearch: "",
      retroplaceSuggestions: [],
      retroplaceItemSuggestions: [],
      retroplaceGame: null,
      activeId: 0,
      idList: [],
      components: [],
      sellerApi
    }
  },
  mounted: function() {
    this.idList = this.$route.params.id.split(",");
    this.activeId = this.idList[0];
    this.loadData();
  },
  methods: {
    loadData: function() {
      var component = this;

      sellerApi.client.get("stock/" + component.activeId)
          .then(function(response) {
            component.game = response.data;
            component.retroplaceSearch = response.data.title + ' ' + response.data.platform;
            component.description = component.game.description;
            component.price = component.game.price;
            if(component.game.type === "Loose") {
              component.components = ["media"];
            } else if(component.game.type === "CIB") {
              component.components = ["media", "box"];
            } else if(component.game.type === "New") {
              component.components = ["media", "box", "manual"];
            }
            // Check for manual in description
            if(component.game.description.includes("Includes manual.") && !component.components.includes("manual")) {
              component.components.push("manual");
            }
          })
          .catch(function(error) {
            alert(error);
          });
    },
    submit: function() {
      var component = this;
      this.submitting = true;
      sellerApi.client.post("list/retroplace", {
        id: this.game.id,
        rp_id: this.retroplaceId,
        price: this.price,
        condition: this.condition,
        description: this.description,
        components: this.components
      })
          .then(function(response) {
            component.submitting = false;
            if(response.data.status === "ok") {
              component.goToNextPage();
            }
          })
          .catch(function(error) {
            component.submitting = false;
            alert(error);
          });
    },
    updatePrice: function(usdPrice) {
      this.price = Math.round(usdPrice / 1.2) + 0.90;
    },
    getRetroplaceItems: function() {
      this.retroplaceItemSuggestions = [];
      sellerApi.client.get('stock/get_retroplace_items', {
        params: {
          search: this.retroplaceSearch
        }
      })
          .then((response) => {
            this.retroplaceItemSuggestions = response.data;
          });
    },
    goToNextPage: function() {
      // Get next id in queue
      this.idList = this.idList.slice(1);
      if(this.idList.length === 0) {
        this.$router.push("/stock");
      } else {
        // Update url (only history)
        this.$router.push("/list/retroplace/" + this.idList.join(","));
        window.scrollTo({top: 0, left: 0});
        this.activeId = this.idList[0];
        this.retroplaceSuggestions = [];
        this.retroplaceItemSuggestions = [];
        this.retroplaceId = "";
        this.loadData();
      }
    }
  }
}
</script>

<style lang="scss">

.page-list-retroplace {
}

</style>
