<template>
  <div class="page-stock">

    <p class="content">
      <router-link to="/stock/new" class="button is-primary">
        <i class="fas fa-plus"></i> &nbsp;Add new stock
      </router-link>
    </p>

    <table class="table" id="stock-table"></table>

  </div>
</template>

<script>

import { DataTable } from 'simple-datatables';

import { sellerApi } from '@/sellerApi';

export default {
  name: 'Stock',
  data: function() {
    return {
      dataTable: false,
      sellerApi: sellerApi
    }
  },
  mounted: function() {
    var component = this;

    sellerApi.client.get("stock/all")
    .then(function(response) {

      var games = [];
      response.data.forEach(function(game) {
        games.push([
          game.id.toString(),
          game.created_at.slice(0, -8),
          game.title,
          game.platform,
          game.region,
          game.type,
          game.condition,
          game.listings.length.toString(),
          game.price
        ]);
      });

      component.dataTable = new DataTable("#stock-table", {
        columns: [
          { select: 1, type: "date", format: "ISO_8601" },
          {
            select: 2,
            render: function(data, cell, row) {
              return "<a href='/stock/" + row.cells[0].innerText + "'>" + cell.innerText + "</a>";
            }
          },
          {
            select: 8,
            type: "number"
          }
        ],
        data: {
          headings: [
            "ID",
            "Date",
            "Title",
            "Platform",
            "Region",
            "Type",
            "Condition",
            "Listings",
            "Price"
          ],
          data: games
        },
      });

    });
  }
}
</script>

<style lang="scss">

@import "../../node_modules/simple-datatables/src/style.css";

</style>
