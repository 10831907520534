<template>
  <div class="home">
    <form v-on:submit.prevent="doSearch">
      <input type="text" class="input" v-model="eanInput" placeholder="Search by EAN code...">

      <input type="submit" class="button is-primary" value="Search">

    </form>

    <div v-if="game.name" class="box">

      <div class="box">
        <p class="content">
          <strong>Database info</strong><br/>
          Name: {{ game.name }}<br/>
          EAN: {{ game.ean }}<br/>
          ID: {{ game.id }}<br/>
          Source: {{ game.source }}<br/>
          Date: {{ game.created_at }}
        </p>
      </div>

      <h1 class="title is-3">{{ priceCharting[activePriceCharting].name }} ({{ priceCharting[activePriceCharting].console }})</h1>

      <p class="tags">Not right? Is it one of these: <span v-for="(price, index) in priceCharting" class="tag is-clickable" v-bind:key="price.id" v-on:click="selectPrice(index)">{{ price.name }} ({{ price.console }})</span></p>

      <div class="columns">
        <div class="column">
          <p><strong>Loose:</strong> ${{ priceCharting[activePriceCharting].price_loose}}</p>
        </div>
        <div class="column">
          <p><strong>CIB:</strong> ${{ priceCharting[activePriceCharting].price_cib}}</p>
        </div>
        <div class="column">
          <p><strong>New:</strong> ${{ priceCharting[activePriceCharting].price_new}}</p>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import { sellerApi } from '@/sellerApi';

export default {
  name: 'Home',
  data: function() {
    return {
      eanInput: '',
      game: {},
      priceCharting: {},
      activePriceCharting: 0
    }
  },
  methods: {
    doSearch: function() {
      var component = this;
      sellerApi.client.get("search/ean/" + this.eanInput)
      .then(function(response) {
        component.game = response.data.game;
        component.priceCharting = response.data.priceCharting;
        component.activePriceCharting = 0;
      })
      .catch(function(error) {
        alert(error);
      });
    },
    selectPrice(index) {
      this.activePriceCharting = index;
    }
  }
}
</script>
