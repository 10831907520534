import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Stock from '../views/Stock';
import NewStock from '../views/NewStock';
import ViewStock from '../views/ViewStock';
import ListHuutonet from "@/views/ListHuutonet";
import ListEbay from "@/views/ListEbay";
import ListRetroplace from "@/views/ListRetroplace";
import Deals from "@/views/Deals";
import ViewDeal from "@/views/ViewDeal";
import Listings from "@/views/Listings";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock
  },
  {
    path: '/stock/new',
    name: 'NewStock',
    component: NewStock
  },
  {
    path: '/stock/:id',
    name: 'ViewStock',
    component: ViewStock
  },
  {
    path: '/list/huutonet/:id',
    name: 'ListHuutonet',
    component: ListHuutonet
  },
  {
    path: '/list/ebay/:id',
    name: 'ListEbay',
    component: ListEbay
  },
  {
    path: '/list/retroplace/:id',
    name: 'ListRetroplace',
    component: ListRetroplace
  },
  {
    path: '/deals',
    name: 'Deals',
    component: Deals
  },
  {
    path: '/deals/:id',
    name: 'ViewDeal',
    component: ViewDeal
  },
  {
    path: '/listings',
    name: 'Listings',
    component: Listings
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
