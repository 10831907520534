<template>
  <div class="page-list-huutonet">

    <div v-if="game">

      <h1 class="title is-4">List {{ game.title }} for sale in Huuto.net</h1>

      <div class="well">
        <a v-bind:href="sellerApi.formatImageUrl(image.filename)" target="_blank" v-for="image in game.images" v-bind:key="image.id">
          <img v-bind:src="sellerApi.formatThumbnailImageUrl(image.filename)" v-bind:alt="image.filename" class="image">
        </a>
      </div>

      <form>

        <div class="field">
          <label class="label">Title</label>
          <div class="control">
            <input class="input" type="text" v-model="title" placeholder="Title of listing">
          </div>
        </div>

        <div class="field">
          <label class="label">Category</label>
          <div class="control">
            <div class="select">
              <select v-model="category">
                <option value='1'>Ajoneuvot</option>
                <option value='2'> - Autojen varusteet ja varaosat</option>
                <option value='3'> - - Autojen lisävarusteet</option>
                <option value='4'> - - Autostereot ja muu autohifi</option>
                <option value='5'> - - Klassikkoautojen varaosat</option>
                <option value='913'> - - Korjaustyökalut ja -laitteet</option>
                <option value='1074'> - - Navigaattorit</option>
                <option value='6'> - - Renkaat ja vanteet</option>
                <option value='7'> - - Varaosat</option>
                <option value='8'> - Autot</option>
                <option value='9'> - - Henkilöautot</option>
                <option value='10'> - - Katsastamattomat autot</option>
                <option value='11'> - - Klassikkoautot</option>
                <option value='12'> - - Matkailuautot ja asuntovaunut</option>
                <option value='13'> - - Paketti- ja kuorma-autot</option>
                <option value='14'> - - Peräkärryt</option>
                <option value='15'> - Kaksipyöräiset ja mopoautot</option>
                <option value='16'> - - Moottoripyörien ajovarusteet</option>
                <option value='17'> - - Moottoripyörien varaosat</option>
                <option value='18'> - - Moottoripyörät</option>
                <option value='19'> - - Mopojen varusteet ja varaosat</option>
                <option value='20'> - - Mopot, skootterit ja mopoautot</option>
                <option value='1125'> - - Mönkijät</option>
                <option value='21'> - Muut</option>
                <option value='22'> - - Autot</option>
                <option value='862'> - - Fanituotteet</option>
                <option value='23'> - - Moottorikelkat</option>
                <option value='25'> - - Polkupyörät</option>
                <option value='26'> - - Veneet ja muut vesiajoneuvot sekä varusteet</option>
                <option value='24'> - - Muut ajoneuvot ja varusteet</option>
                <option value='27'>Antiikki</option>
                <option value='28'> - Antiikki yli 100 v</option>
                <option value='29'> - - Huonekalut yli 100 v</option>
                <option value='30'> - - Korut ja hopea yli 100 v</option>
                <option value='31'> - - Lasi ja posliini yli 100 v</option>
                <option value='32'> - - Muu antiikki yli 100 v</option>
                <option value='46'> - Vanhat tavarat yli 50 v</option>
                <option value='47'> - - Huonekalut yli 50 v</option>
                <option value='48'> - - Korut ja hopea yli 50 v</option>
                <option value='49'> - - Lasi ja posliini yli 50 v</option>
                <option value='50'> - - Muu antiikki yli 50 v</option>
                <option value='33'> - Muut</option>
                <option value='34'> - - Antiikki- ja keräilymatot</option>
                <option value='35'> - - Antiikki- ja taidelehdet sekä -luettelot</option>
                <option value='37'> - - Taidekirjat</option>
                <option value='36'> - - Muu antiikki</option>
                <option value='86'>Elokuvat</option>
                <option value='845'> - Blu-ray</option>
                <option value='846'> - - Anime</option>
                <option value='848'> - - Dokumentit</option>
                <option value='849'> - - Draama</option>
                <option value='850'> - - Jännitys</option>
                <option value='851'> - - Kauhu</option>
                <option value='852'> - - Kaukoitä</option>
                <option value='853'> - - Komedia</option>
                <option value='854'> - - Kotimaiset</option>
                <option value='855'> - - Lastenelokuvat</option>
                <option value='856'> - - Musiikki</option>
                <option value='857'> - - Scifi ja fantasia</option>
                <option value='858'> - - Sota ja western</option>
                <option value='859'> - - Toiminta</option>
                <option value='860'> - - TV-sarjat</option>
                <option value='861'> - - Urheilu</option>
                <option value='847'> - - Blu-ray - Muut</option>
                <option value='87'> - DVD</option>
                <option value='88'> - - Anime</option>
                <option value='89'> - - Dokumentit</option>
                <option value='90'> - - Draama</option>
                <option value='91'> - - Jännitys</option>
                <option value='92'> - - Kauhu</option>
                <option value='93'> - - Kaukoitä</option>
                <option value='94'> - - Komedia</option>
                <option value='95'> - - Kotimaiset</option>
                <option value='96'> - - Lastenelokuvat</option>
                <option value='97'> - - DVD - Musiikki</option>
                <option value='99'> - - Scifi ja fantasia</option>
                <option value='100'> - - Sota ja western</option>
                <option value='102'> - - Toiminta</option>
                <option value='101'> - - TV-sarjat</option>
                <option value='103'> - - Urheilu</option>
                <option value='98'> - - DVD - Muut</option>
                <option value='110'> - Videot</option>
                <option value='111'> - - Anime</option>
                <option value='113'> - - Draama</option>
                <option value='112'> - - Dokumentit</option>
                <option value='114'> - - Jännitys</option>
                <option value='115'> - - Kauhu</option>
                <option value='116'> - - Kaukoitä</option>
                <option value='117'> - - Komedia</option>
                <option value='118'> - - Kotimaiset</option>
                <option value='119'> - - Lastenelokuvat</option>
                <option value='120'> - - Videot - Musiikki</option>
                <option value='122'> - - Scifi ja fantasia</option>
                <option value='123'> - - Sota ja western</option>
                <option value='125'> - - Toiminta</option>
                <option value='124'> - - TV-sarjat</option>
                <option value='126'> - - Urheilu</option>
                <option value='121'> - - Videot - Muut</option>
                <option value='106'> - Muut formaatit</option>
                <option value='844'> - - HD-DVD</option>
                <option value='108'> - - Laserdiscit</option>
                <option value='109'> - - Video CD:t - Yleinen</option>
                <option value='104'> - Muut</option>
                <option value='305'> - - Elokuva</option>
                <option value='127'>Harrastukset</option>
                <option value='128'> - Aseet</option>
                <option value='129'> - - Airsoft-aseet ja tarvikkeet</option>
                <option value='130'> - - Luvanvaraiset aseet ja tarvikkeet</option>
                <option value='131'> - - Muut aseet ja tarvikkeet</option>
                <option value='1079'> - Askartelu</option>
                <option value='1091'> - - Helmet</option>
                <option value='1092'> - - Korttikoristeet</option>
                <option value='1093'> - - Korttipohjat ja kuoret</option>
                <option value='1094'> - - Korutarvikkeet</option>
                <option value='1095'> - - Leikkurit</option>
                <option value='1096'> - - Leimasimet ja leimasintarvikkeet</option>
                <option value='1097'> - - Nauhat</option>
                <option value='1098'> - - Paperit</option>
                <option value='1099'> - - Tarrat ja teipit</option>
                <option value='1100'> - - Liidut, maalit ja tussit</option>
                <option value='1101'> - - Muut askartelutarvikkeet</option>
                <option value='132'> - Kalastus, erä ja retkeily</option>
                <option value='156'> - - Erä ja retkeily</option>
                <option value='133'> - - Kalastuslehdet ja -kirjallisuus</option>
                <option value='134'> - - Kelat ja vavat</option>
                <option value='136'> - - Perhokalastusvälineet ja perhot</option>
                <option value='137'> - - Pilkkivavat, -kelat ja pilkit</option>
                <option value='138'> - - Vieheet ja uistimet</option>
                <option value='135'> - - Muut kalastusvälineet ja -tarvikkeet</option>
                <option value='139'> - Käsityöt</option>
                <option value='914'> - - Kaavat</option>
                <option value='141'> - - Kankaat ja muut käsityömateriaalit</option>
                <option value='142'> - - Käsityötarvikkeet</option>
                <option value='143'> - Lemmikkitarvikkeet</option>
                <option value='144'> - - Akvaariot</option>
                <option value='145'> - - Hevoset</option>
                <option value='146'> - - Jyrsijät</option>
                <option value='147'> - - Kissat</option>
                <option value='148'> - - Koirat</option>
                <option value='149'> - - Matelijat</option>
                <option value='150'> - - Muut lemmikkitarvikkeet</option>
                <option value='151'> - Liikunta ja urheilu</option>
                <option value='152'> - - Kuntoilu- ja kuntosalivälineet</option>
                <option value='153'> - - Mailapelit</option>
                <option value='912'> - - Polkupyörien osat ja varusteet</option>
                <option value='157'> - - Skeittaus ja rullaluistelu</option>
                <option value='158'> - - Sukellus ja muu vesiurheilu</option>
                <option value='159'> - - Talviurheiluvälineet</option>
                <option value='160'> - - Urheiluasut ja -jalkineet</option>
                <option value='154'> - - Muu urheilu ja liikunta</option>
                <option value='174'> - Valokuvaus</option>
                <option value='175'> - - Digikamerat</option>
                <option value='176'> - - Digikameroiden akut</option>
                <option value='177'> - - Järjestelmäkamerat</option>
                <option value='178'> - - Muistikortit</option>
                <option value='181'> - - Objektiivit</option>
                <option value='182'> - - Valokuvausvarusteet</option>
                <option value='183'> - - Videokamerat</option>
                <option value='180'> - - Muut kamerat</option>
                <option value='179'> - - Muu valokuvaukseen liittyvä</option>
                <option value='161'> - Muut harrastukset</option>
                <option value='162'> - - Harrasteradiot ja tarvikkeet</option>
                <option value='164'> - - Pienoismallit</option>
                <option value='165'> - - Radio-ohjattavat</option>
                <option value='163'> - - Muut</option>
                <option value='939'>Kauneus ja terveys</option>
                <option value='940'> - Kauneudenhoito ja hyvinvointi</option>
                <option value='941'> - - Hajuvedet, tuoksut</option>
                <option value='942'> - - Hiustuotteet</option>
                <option value='943'> - - Kauneudenhoidon pienlaitteet</option>
                <option value='949'> - - Kynnet, käsienhoito</option>
                <option value='944'> - - Meikit, muu kosmetiikka</option>
                <option value='935'> - - Meikki- ja kosmetiikkalaukut</option>
                <option value='945'> - - Miesten kosmetiikka</option>
                <option value='946'> - - Pidennykset, peruukit</option>
                <option value='947'> - - Terveystuotteet</option>
                <option value='948'> - - Muu kauneudenhoito</option>
                <option value='184'>Kellot ja korut</option>
                <option value='185'> - Jalometallikorut</option>
                <option value='186'> - - Antiikkikorut ja -hopea, yli 100 v.</option>
                <option value='187'> - - Hopeakorut</option>
                <option value='188'> - - Jalo- ja korukivet</option>
                <option value='189'> - - Kalevalakorut</option>
                <option value='191'> - - Timantti-, kulta- ja platinakorut</option>
                <option value='190'> - - Muut jalometallikorut</option>
                <option value='192'> - Kellot</option>
                <option value='193'> - - Miesten rannekellot</option>
                <option value='195'> - - Naisten rannekellot</option>
                <option value='196'> - - Pöytäkellot</option>
                <option value='197'> - - Seinäkellot</option>
                <option value='198'> - - Taskukellot</option>
                <option value='194'> - - Muut kellot</option>
                <option value='199'> - Muut korut, ei jalometalliset</option>
                <option value='915'> - - Kaulakorut ja riipukset</option>
                <option value='200'> - - Korvakorut</option>
                <option value='201'> - - Lävistyskorut</option>
                <option value='916'> - - Rannekorut</option>
                <option value='917'> - - Sormukset</option>
                <option value='202'> - - Muut korut</option>
                <option value='203'>Keräily</option>
                <option value='204'> - Militaria</option>
                <option value='209'> - - ennen 1914</option>
                <option value='205'> - - 1914-1918</option>
                <option value='206'> - - 1919-1938</option>
                <option value='207'> - - 1939-1945</option>
                <option value='208'> - - 1946 ></option>
                <option value='210'> - - Sotahistoria</option>
                <option value='224'> - Postikortit</option>
                <option value='225'> - - Autot ja rautatiet</option>
                <option value='226'> - - Eläimet ja kasvit</option>
                <option value='227'> - - Huumori</option>
                <option value='228'> - - Joulukortit</option>
                <option value='229'> - - Kirkot ja uskonto</option>
                <option value='230'> - - Laivat ja ilmailu</option>
                <option value='233'> - - Pääsiäiskortit</option>
                <option value='234'> - - Sarjakuvat</option>
                <option value='235'> - - Signeeratut taiteilijakortit</option>
                <option value='236'> - - Sotilas-, vaakuna- ja lippuaiheiset</option>
                <option value='237'> - - Suomalaiset paikkakunta- ja maisemakortit</option>
                <option value='238'> - - Taidekortit</option>
                <option value='239'> - - Tunnetut henkilöt</option>
                <option value='240'> - - Ulkomaiset paikkakunta- ja maisemakortit</option>
                <option value='241'> - - Urheilu</option>
                <option value='232'> - - Muut onnittelukortit</option>
                <option value='231'> - - Postikortit - Muut</option>
                <option value='242'> - Postimerkit</option>
                <option value='244'> - - Aihekokoelmat</option>
                <option value='243'> - - Ahvenanmaa</option>
                <option value='245'> - - Erikoisleimat</option>
                <option value='250'> - - Suomalaiset ensipäiväkuoret</option>
                <option value='248'> - - Suomalaiset - luovutetut alueet</option>
                <option value='251'> - - Suomalaiset v. 1917 asti</option>
                <option value='249'> - - Suomalaiset 1917 jälkeen</option>
                <option value='247'> - - Postilähetykset</option>
                <option value='252'> - - Ulkomaiset</option>
                <option value='246'> - - Postimerkit - Muut leimat</option>
                <option value='253'> - Rahat ja mitalit</option>
                <option value='899'> - - Arvopaperit ja osakekirjat</option>
                <option value='254'> - - Eurokolikot ja -setelit</option>
                <option value='255'> - - Mitalit ja kunniamerkit</option>
                <option value='256'> - - Pinssit</option>
                <option value='257'> - - Suomalaiset juhlarahat ja vuosisarjat</option>
                <option value='258'> - - Suomalaiset kolikot - markka</option>
                <option value='259'> - - Suomalaiset setelit - markka</option>
                <option value='260'> - - Ulkomaiset kolikot</option>
                <option value='261'> - - Ulkomaiset setelit</option>
                <option value='217'> - Muut kortit</option>
                <option value='218'> - - Mtg-kortit</option>
                <option value='221'> - - Pokemon- ja Digimon-kortit</option>
                <option value='222'> - - Puhelinkortit</option>
                <option value='223'> - - Urheiluaiheiset keräilykortit</option>
                <option value='220'> - - Muut pelattavat keräilykortit</option>
                <option value='219'> - - Muut keräilykortit</option>
                <option value='211'> - Muut</option>
                <option value='212'> - - Etiketit</option>
                <option value='213'> - - Julisteet</option>
                <option value='214'> - - Kiiltokuvat</option>
                <option value='216'> - - Tarrat</option>
                <option value='215'> - - Muut keräilykohteet</option>
                <option value='262'>Kirjat ja lehdet</option>
                <option value='683'> - Harrastekirjallisuus</option>
                <option value='919'> - - Ajoneuvot (muu kuin auto)</option>
                <option value='700'> - - Elämäntapa</option>
                <option value='686'> - - Kotieläimet ja lemmikit</option>
                <option value='695'> - - Kotitalous ja keittokirjat</option>
                <option value='699'> - - Kuvataide ja valokuvaus</option>
                <option value='696'> - - Käsityöt ja askartelu</option>
                <option value='312'> - - Liikunta ja urheilu</option>
                <option value='689'> - - Matkailu</option>
                <option value='697'> - - Metsästys, kalastus ja erä</option>
                <option value='316'> - - Musiikkiaiheiset kirjat ja lehdet</option>
                <option value='362'> - - Puutarhakirjallisuus</option>
                <option value='698'> - - Sisustus ja rakentaminen</option>
                <option value='77'> - - Atk-aiheiset kirjat ja lehdet</option>
                <option value='319'> - - Tekniikka</option>
                <option value='918'> - - Veneily ja purjehdus</option>
                <option value='263'> - Kaunokirjallisuus</option>
                <option value='1114'> - - Kotimaiset dekkarit ja jännitys</option>
                <option value='1115'> - - Ulkomaiset dekkarit ja jännitys</option>
                <option value='646'> - - Elämänkerrat ja muistelmat</option>
                <option value='649'> - - Hengellinen kirjallisuus</option>
                <option value='647'> - - Historialliset romaanit</option>
                <option value='648'> - - Novellikokoelmat ja muut kokoelmateokset</option>
                <option value='265'> - - Romantiikka</option>
                <option value='269'> - - Runous</option>
                <option value='266'> - - Scifi ja fantasia</option>
                <option value='1116'> - - Suomenkielinen kaunokirjallisuus</option>
                <option value='1117'> - - Käännetty kaunokirjallisuus</option>
                <option value='655'> - - Englanninkielinen kaunokirjallisuus</option>
                <option value='654'> - - Ruotsinkielinen kaunokirjallisuus</option>
                <option value='656'> - - Muu vieraskielinen kaunokirjallisuus</option>
                <option value='657'> - - Kirjapaketit</option>
                <option value='658'> - Lasten ja nuorten kirjallisuus</option>
                <option value='659'> - - Lasten kuvakirjat</option>
                <option value='661'> - - Lasten romaanit</option>
                <option value='660'> - - Lasten tietokirjat</option>
                <option value='665'> - - Hevoskirjat</option>
                <option value='662'> - - Jännitys ja seikkailu</option>
                <option value='663'> - - Scifi ja fantasia</option>
                <option value='664'> - - Tyttökirjat</option>
                <option value='666'> - - Muut nuorten romaanit</option>
                <option value='667'> - - Kirjapaketit</option>
                <option value='1066'> - - Äänikirjat (lasten)</option>
                <option value='271'> - Lehdet</option>
                <option value='669'> - - Asuminen, piha ja ruoka</option>
                <option value='674'> - - Erä ja kalastus</option>
                <option value='675'> - - Kuntoilu ja terveys</option>
                <option value='672'> - - Käsityö ja askartelu</option>
                <option value='668'> - - Naiset, muoti ja kauneus</option>
                <option value='670'> - - Nuorten lehdet</option>
                <option value='671'> - - Perhe ja lapset</option>
                <option value='673'> - - Teknologia ja autot</option>
                <option value='676'> - - Uutiset, talous ja tiede</option>
                <option value='677'> - - Muut lehdet</option>
                <option value='280'> - Lukion oppikirjat</option>
                <option value='281'> - - Biologia ja maantieto</option>
                <option value='282'> - - Englanti</option>
                <option value='283'> - - Fysiikka ja kemia</option>
                <option value='284'> - - Historia ja yhteiskuntaoppi</option>
                <option value='285'> - - Matematiikka</option>
                <option value='287'> - - Psykologia</option>
                <option value='288'> - - Ruotsi</option>
                <option value='289'> - - Uskonto ja filosofia</option>
                <option value='290'> - - Äidinkieli</option>
                <option value='286'> - - Muut kielet</option>
                <option value='291'> - - Muut oppiaineet</option>
                <option value='276'> - Muut oppikirjat</option>
                <option value='279'> - - Muut oppikirjat</option>
                <option value='292'> - Sarjakuvat</option>
                <option value='680'> - - Aku Ankka -lehdet</option>
                <option value='681'> - - Aku Ankka -taskukirjat</option>
                <option value='678'> - - Disney-lehdet</option>
                <option value='679'> - - Disney-taskukirjat</option>
                <option value='294'> - - Huumori</option>
                <option value='295'> - - Lännensarjat</option>
                <option value='296'> - - Manga</option>
                <option value='301'> - - Toiminta ja seikkailu</option>
                <option value='297'> - - Muut suomenkieliset albumit</option>
                <option value='298'> - - Muut suomenkieliset lehdet</option>
                <option value='299'> - - Muut vieraskieliset albumit</option>
                <option value='300'> - - Muut vieraskieliset lehdet</option>
                <option value='302'> - Tietokirjallisuus</option>
                <option value='684'> - - Eläimet</option>
                <option value='687'> - - Filosofia</option>
                <option value='308'> - - Historia</option>
                <option value='685'> - - Kasvit</option>
                <option value='896'> - - Kasvatustiede</option>
                <option value='311'> - - Liiketalous</option>
                <option value='314'> - - Lääketiede ja terveys</option>
                <option value='690'> - - Maantiede ja kansatiede</option>
                <option value='691'> - - Oikeustiede</option>
                <option value='688'> - - Psykologia</option>
                <option value='317'> - - Rajatieto</option>
                <option value='322'> - - Sanakirjat ja kielitiede</option>
                <option value='320'> - - Tähtitiede, fysiikka ja matematiikka</option>
                <option value='321'> - - Uskonto</option>
                <option value='323'> - - Yhteiskunta ja politiikka</option>
                <option value='693'> - - Yleisteokset</option>
                <option value='694'> - - Muut tietokirjat</option>
                <option value='273'> - Muut</option>
                <option value='274'> - - Kartat</option>
                <option value='277'> - - Keräilyharvinaisuudet</option>
                <option value='275'> - - Muut</option>
                <option value='1067'> - - Äänikirjat</option>
                <option value='324'>Kodin elektroniikka</option>
                <option value='325'> - Televisiot, Blu-Ray, DVD ja videot</option>
                <option value='842'> - - Kuvaputkitelevisiot</option>
                <option value='843'> - - Taulutelevisiot</option>
                <option value='327'> - - Digiboksit ja satelliittilaitteet</option>
                <option value='326'> - - DVD- ja Blu-Ray -soittimet</option>
                <option value='330'> - - Videonauhurit ja -tarvikkeet</option>
                <option value='331'> - - Videotykit</option>
                <option value='780'> - - Kaukosäätimet ja muut varusteet</option>
                <option value='779'> - Kodinkoneet</option>
                <option value='788'> - - Astianpesukoneet</option>
                <option value='789'> - - Jääkaapit ja pakastimet</option>
                <option value='790'> - - Uunit, hellat ja mikroaaltouunit</option>
                <option value='791'> - - Muut keittiön kodinkoneet</option>
                <option value='782'> - - Pölynimurit ja muut siivousvälineet</option>
                <option value='356'> - - Pesukoneet ja mankelit</option>
                <option value='787'> - - Muut kodinkoneet</option>
                <option value='334'> - Musiikkilaitteet</option>
                <option value='772'> - - Kaiuttimet, vahvistimet ja muut varusteet</option>
                <option value='336'> - - MP3-soittimet ja muut kannettavat</option>
                <option value='771'> - - Radiot</option>
                <option value='770'> - - Stereot</option>
                <option value='332'> - Muu elektroniikka</option>
                <option value='773'> - - Ompelukoneet ja saumurit</option>
                <option value='774'> - - Vaa'at ja mittarit</option>
                <option value='778'> - - Akut ja paristot</option>
                <option value='333'> - - Muu kodin elektroniikka</option>
                <option value='338'>Koti</option>
                <option value='339'> - Huonekalut</option>
                <option value='800'> - - Hyllyt</option>
                <option value='801'> - - Kaapit</option>
                <option value='341'> - - Kylpyhuonekalusteet ja säilytys</option>
                <option value='342'> - - Lastenhuoneen kalusteet</option>
                <option value='802'> - - Lipastot</option>
                <option value='812'> - - Naulakot ja vaatetelineet</option>
                <option value='806'> - - Nojatuolit</option>
                <option value='344'> - - Pöydät</option>
                <option value='804'> - - Ruokailuryhmät</option>
                <option value='805'> - - Sohvat</option>
                <option value='346'> - - Sängyt ja patjat</option>
                <option value='348'> - - Tuolit ja jakkarat</option>
                <option value='803'> - - Muu säilytys</option>
                <option value='343'> - - Muut huonekalut</option>
                <option value='1064'> - Sisustaminen</option>
                <option value='810'> - - Koriste-esineet</option>
                <option value='811'> - - Kynttilänjalat, tuikut ja kyntteliköt</option>
                <option value='808'> - - Lastenhuoneen sisustus ja tekstiilit</option>
                <option value='367'> - - Matot ja seinävaatteet</option>
                <option value='369'> - - Peilit ja kellot</option>
                <option value='370'> - - Pöytäliinat</option>
                <option value='809'> - - Sisustustaulut</option>
                <option value='1065'> - - Sisustuspaketit</option>
                <option value='372'> - - Valaisimet</option>
                <option value='373'> - - Verhot</option>
                <option value='374'> - - Vuodevaatteet ja tyynyt</option>
                <option value='368'> - - Muu sisustus</option>
                <option value='349'> - Keittiötarvikkeet, astiat</option>
                <option value='798'> - - Juomalasit</option>
                <option value='797'> - - Kahvikupit ja mukit</option>
                <option value='799'> - - Keittiökalusteet</option>
                <option value='792'> - - Keittiövälineet ja tarvikkeet</option>
                <option value='795'> - - Ruokailuastiat</option>
                <option value='796'> - - Ruokailuvälineet</option>
                <option value='793'> - - Säilytysastiat ja säilytysrasiat</option>
                <option value='794'> - - Tarjoiluastiat</option>
                <option value='353'> - Kodinkoneet ja kodin tarvikkeet</option>
                <option value='815'> - - Kodin työkalut</option>
                <option value='818'> - - Muut kodinkoneet</option>
                <option value='355'> - - Muut kodin tarvikkeet</option>
                <option value='357'> - Piha, puutarha ja huonekasvit</option>
                <option value='358'> - - Kastelujärjestelmät</option>
                <option value='359'> - - Kasvit, siemenet ja kukkasipulit</option>
                <option value='363'> - - Kukkaruukut ja kukkalaatikot</option>
                <option value='907'> - - Liput ja viirit</option>
                <option value='360'> - - Piharakennukset ja aidat</option>
                <option value='361'> - - Puutarhakalusteet ja pihakoristeet</option>
                <option value='364'> - - Työkalut, työkoneet ja tarvikkeet</option>
                <option value='365'> - - Ulkovalaistus ja sähkötarvikkeet</option>
                <option value='950'> - Toimisto</option>
                <option value='558'> - - Toimistokalusteet</option>
                <option value='1068'> - - Toimistotarvikkeet</option>
                <option value='375'>Lastentarvikkeet</option>
                <option value='376'> - Lastenvaatteet 86-140 cm</option>
                <option value='730'> - - Poikien paidat ja puserot 86-104 cm</option>
                <option value='731'> - - Poikien housut, shortsit ja  haalarit 86-104 cm</option>
                <option value='732'> - - Poikien ulkovaatteet 86-104 cm</option>
                <option value='733'> - - Poikien paidat ja puserot 110-140 cm</option>
                <option value='734'> - - Poikien housut, shortsit ja haalarit 110-140 cm</option>
                <option value='735'> - - Poikien ulkovaatteet 110-140 cm</option>
                <option value='738'> - - Poikien asusteet</option>
                <option value='739'> - - Muut poikien vaatteet</option>
                <option value='729'> - - Poikien sisäkengät ja kesäkengät</option>
                <option value='728'> - - Poikien välikausikengät ja talvikengät</option>
                <option value='740'> - - Poikien vaatepaketit</option>
                <option value='741'> - - Tyttöjen paidat ja puserot 86-104 cm</option>
                <option value='742'> - - Tyttöjen housut, shortsit ja haalarit 86-104 cm</option>
                <option value='743'> - - Tyttöjen mekot ja hameet 86-104 cm</option>
                <option value='744'> - - Tyttöjen ulkovaatteet 86-104 cm</option>
                <option value='745'> - - Tyttöjen paidat ja puserot 110-140 cm</option>
                <option value='746'> - - Tyttöjen housut, shortsit ja haalarit 110-140 cm</option>
                <option value='747'> - - Tyttöjen mekot ja hameet 110-140 cm</option>
                <option value='748'> - - Tyttöjen ulkovaatteet 110-140 cm</option>
                <option value='749'> - - Tyttöjen asusteet</option>
                <option value='750'> - - Muut tyttöjen vaatteet</option>
                <option value='737'> - - Tyttöjen sisäkengät ja kesäkengät</option>
                <option value='736'> - - Tyttöjen välikausikengät ja talvikengät</option>
                <option value='751'> - - Tyttöjen vaatepaketit</option>
                <option value='841'> - Tarvikkeet</option>
                <option value='388'> - - Lastenvaunut, -rattaat ja -istuimet</option>
                <option value='386'> - - Lastenhoitovälineet</option>
                <option value='385'> - - Kestovaipat</option>
                <option value='384'> - Lelut</option>
                <option value='824'> - - Action-figuurit</option>
                <option value='821'> - - Barbie, Bratz ja muut nuket</option>
                <option value='828'> - - Brio ja Fisher Price</option>
                <option value='825'> - - Eläinfiguurit</option>
                <option value='832'> - - Junaradat, autoradat ja radio-ohjattavat lelut</option>
                <option value='834'> - - Lasten askartelutarvikkeet</option>
                <option value='835'> - - Lasten koulutarvikkeet</option>
                <option value='836'> - - Lasten lautapelit, palapelit ja korttipelit</option>
                <option value='838'> - - Lasten äänikirjat ja lastenlaulut</option>
                <option value='827'> - - Legot</option>
                <option value='833'> - - Leikkimatot</option>
                <option value='840'> - - Lelupaketit</option>
                <option value='826'> - - Muut lelufiguurit</option>
                <option value='839'> - - Muut lelut</option>
                <option value='822'> - - Nukenvaatteet ja tarvikkeet</option>
                <option value='823'> - - Pehmolelut</option>
                <option value='829'> - - Pikkuautot ja muut leluautot</option>
                <option value='837'> - - Pokemon-kortit ja muut lasten keräilykortit</option>
                <option value='831'> - - Pulkat, rattikelkat ja liukurit</option>
                <option value='830'> - - Rantalelut ja ulkolelut</option>
                <option value='819'> - - Vauvan lelut</option>
                <option value='820'> - - Vauvanuket</option>
                <option value='390'> - Vauvanvaatteet</option>
                <option value='606'> - - Vauvanvaatteet 50-58 cm</option>
                <option value='707'> - - Poikavauvojen vaatteet 60-68 cm</option>
                <option value='706'> - - Tyttövauvojen vaatteet 60-68 cm</option>
                <option value='708'> - - Poikavauvojen vaatteet 70-78 cm</option>
                <option value='709'> - - Tyttövauvojen vaatteet 70-78 cm</option>
                <option value='710'> - - Poikavauvojen vaatteet 80-86 cm</option>
                <option value='711'> - - Tyttövauvojen vaatteet 80-86 cm</option>
                <option value='712'> - - Vauvojen ulkovaatteet 60-68 cm</option>
                <option value='713'> - - Vauvojen ulkovaatteet 70-78 cm</option>
                <option value='714'> - - Vauvojen ulkovaatteet 80-86 cm</option>
                <option value='604'> - - Poikavauvojen vaatepaketit -86 cm</option>
                <option value='605'> - - Tyttövauvojen vaatepaketit -86 cm</option>
                <option value='929'>Laukut</option>
                <option value='930'> - Laukut ja lompakot</option>
                <option value='931'> - - Käsilaukut, naisten laukut</option>
                <option value='975'> - - Lasten ja nuorten laukut</option>
                <option value='932'> - - Lompakot, kukkarot</option>
                <option value='933'> - - Matkalaukut ja lentolaukut</option>
                <option value='936'> - - Rinkat ja reput</option>
                <option value='937'> - - Salkut, miesten laukut</option>
                <option value='938'> - - Muut laukut</option>
                <option value='900'>Matkailu</option>
                <option value='909'> - Matkat</option>
                <option value='1076'> - - Junaliput</option>
                <option value='906'> - - Kaukomatkat</option>
                <option value='905'> - - Kaupunkilomat</option>
                <option value='901'> - - Lennot</option>
                <option value='920'> - - Lomaosakkeet</option>
                <option value='921'> - - Mökkilomat</option>
                <option value='910'> - - Pakettimatkat</option>
                <option value='904'> - - Rantalomat</option>
                <option value='903'> - - Risteilyt ja kiertomatkat</option>
                <option value='902'> - - Muut matkat</option>
                <option value='922'> - Matkailutarvikkeet</option>
                <option value='924'> - - Muut matkailutarvikkeet</option>
                <option value='403'>Musiikki</option>
                <option value='404'> - CD-levyt</option>
                <option value='925'> - - Country</option>
                <option value='405'> - - Dance, Tekno</option>
                <option value='406'> - - Heavy, Metal</option>
                <option value='407'> - - Iskelmä</option>
                <option value='408'> - - Jazz, Blues</option>
                <option value='409'> - - Klassinen</option>
                <option value='926'> - - Lasten musiikki</option>
                <option value='410'> - - Maailmanmusiikki, kansanmusiikki</option>
                <option value='412'> - - Progressiivinen rock</option>
                <option value='413'> - - Punk</option>
                <option value='414'> - - Soul, R&B, Hip Hop, Rap</option>
                <option value='415'> - - Suomalainen Rock ja Pop</option>
                <option value='416'> - - Ulkomainen pop</option>
                <option value='417'> - - Ulkomainen rock</option>
                <option value='411'> - - CD-levyt: Muu musiikki</option>
                <option value='427'> - Soittimet ja nuotit</option>
                <option value='428'> - - Kitarat ja kitaravahvistimet</option>
                <option value='429'> - - Kosketinsoittimet</option>
                <option value='431'> - - Nuotit</option>
                <option value='432'> - - PA- ja studiolaitteet</option>
                <option value='433'> - - Rummut ja muut lyömäsoittimet</option>
                <option value='430'> - - Muut soittimet</option>
                <option value='434'> - Vinyylilevyt (LP)</option>
                <option value='435'> - - Dance, Tekno</option>
                <option value='436'> - - Heavy, Metal</option>
                <option value='437'> - - Iskelmä</option>
                <option value='438'> - - Jazz, Blues</option>
                <option value='439'> - - Klassinen</option>
                <option value='440'> - - Maailmanmusiikki, kansanmusiikki</option>
                <option value='442'> - - Progressiivinen rock</option>
                <option value='443'> - - Punk</option>
                <option value='444'> - - Soul, R&B, Hip Hop, Rap</option>
                <option value='445'> - - Suomalainen Rock ja Pop</option>
                <option value='446'> - - Ulkomainen pop</option>
                <option value='447'> - - Ulkomainen rock</option>
                <option value='441'> - - Vinyylit: Muu musiikki</option>
                <option value='422'> - Muut tallenteet</option>
                <option value='927'> - - Blu-ray - musiikki</option>
                <option value='424'> - - Kasetit</option>
                <option value='425'> - - Keräilyharvinaisuudet</option>
                <option value='418'> - Muut</option>
                <option value='419'> - - Fanituotteet</option>
                <option value='421'> - - Muu musiikkitarvike</option>
                <option value='448'>Pelit</option>
                <option value='449'> - Konsolipelit</option>
                <option value='456'> - - Nintendo-laitteet</option>
                <option value='457'> - - Nintendo-pelit</option>
                <option value='462'> - - PlayStation-laitteet</option>
                <option value='463'> - - PlayStation-pelit</option>
                <option value='458'> - - PlayStation 2 -laitteet</option>
                <option value='459'> - - PlayStation 2 -pelit</option>
                <option value='460'> - - PlayStation 3 -laitteet</option>
                <option value='461'> - - PlayStation 3 -pelit</option>
                <option value='1102'> - - PlayStation 4 -laitteet</option>
                <option value='1103'> - - PlayStation 4 -pelit</option>
                <option value='1147'> - - PlayStation 5 -laitteet</option>
                <option value='1148'> - - PlayStation 5 -pelit</option>
                <option value='464'> - - Sega-laitteet</option>
                <option value='465'> - - Sega-pelit</option>
                <option value='454'> - - Wii-laitteet</option>
                <option value='455'> - - Wii-pelit</option>
                <option value='1104'> - - WiiU-laitteet</option>
                <option value='1105'> - - WiiU-pelit</option>
                <option value='468'> - - Xbox-laitteet</option>
                <option value='469'> - - Xbox-pelit</option>
                <option value='466'> - - Xbox 360 -laitteet</option>
                <option value='467'> - - Xbox 360 -pelit</option>
                <option value='1106'> - - Xbox One -laitteet</option>
                <option value='1107'> - - Xbox One -pelit</option>
                <option value='453'> - - Muut pelikoneet</option>
                <option value='452'> - - Muiden pelikoneiden pelit</option>
                <option value='470'> - Käsikonsolit</option>
                <option value='471'> - - Game Boy -laitteet</option>
                <option value='472'> - - Game Boy -pelit</option>
                <option value='475'> - - Nintendo DS -laitteet</option>
                <option value='476'> - - Nintendo DS -pelit</option>
                <option value='477'> - - Sony PSP -laitteet</option>
                <option value='478'> - - Sony PSP -pelit</option>
                <option value='1108'> - - 3DS-laitteet</option>
                <option value='1109'> - - 3DS-pelit</option>
                <option value='1110'> - - PlayStation Vita -laitteet</option>
                <option value='1111'> - - PlayStation Vita -pelit</option>
                <option value='474'> - - Muut käsikonsolit</option>
                <option value='473'> - - Muut käsikonsolien pelit</option>
                <option value='493'> - PC-pelit</option>
                <option value='494'> - - Action-pelit (PC)</option>
                <option value='495'> - - Ajopelit (PC)</option>
                <option value='497'> - - Puzzle-pelit (PC)</option>
                <option value='498'> - - Seikkailu- ja roolipelit (PC)</option>
                <option value='499'> - - Simulaattorit (PC)</option>
                <option value='500'> - - Strategia- ja sotapelit (PC)</option>
                <option value='501'> - - Urheilupelit (PC)</option>
                <option value='496'> - - Muut pelit (PC)</option>
                <option value='491'> - Muut tietokoneet</option>
                <option value='492'> - - Muiden tietokoneiden pelit</option>
                <option value='479'> - Muut pelit</option>
                <option value='480'> - - Internet-pelit (hahmot, välineet, virtuaaliraha)</option>
                <option value='167'> - - Lasten lautapelit</option>
                <option value='168'> - - Lauta- ja strategiapelit</option>
                <option value='169'> - - Palapelit ja korttipelit</option>
                <option value='928'> - - Peliautomaatit ja pöytäpelit</option>
                <option value='170'> - - Rooli-, strategia-, lauta-, ja korttipelit</option>
                <option value='171'> - - Roolipelit ja LARP-rekvisiitta</option>
                <option value='172'> - - Ulkopelit</option>
                <option value='173'> - - Warhammer ja muut figuuripelit</option>
                <option value='502'>Puhelimet</option>
                <option value='953'> - Matkapuhelimet, Apple</option>
                <option value='898'> - - Matkapuhelimet, Apple</option>
                <option value='958'> - - Kaapelit</option>
                <option value='959'> - - Laturit</option>
                <option value='960'> - - Muut lisätarvikkeet</option>
                <option value='1069'> - Matkapuhelimet, HTC</option>
                <option value='1070'> - - Matkapuhelimet, HTC</option>
                <option value='1072'> - - Akut</option>
                <option value='1071'> - - Kaapelit, laturit</option>
                <option value='1073'> - - Muut tarvikkeet</option>
                <option value='954'> - Matkapuhelimet, Nokia</option>
                <option value='505'> - - Matkapuhelimet, Nokia</option>
                <option value='961'> - - Akut</option>
                <option value='962'> - - Kaapelit</option>
                <option value='963'> - - Laturit</option>
                <option value='964'> - - Muut tarvikkeet</option>
                <option value='955'> - Matkapuhelimet, Samsung</option>
                <option value='506'> - - Matkapuhelimet, Samsung</option>
                <option value='965'> - - Akut</option>
                <option value='966'> - - Kaapelit</option>
                <option value='967'> - - Laturit</option>
                <option value='968'> - - Muut tarvikkeet</option>
                <option value='956'> - Matkapuhelimet, Sony Ericsson</option>
                <option value='508'> - - Matkapuhelimet, Sony Ericsson</option>
                <option value='969'> - - Akut</option>
                <option value='970'> - - Kaapelit</option>
                <option value='971'> - - Laturit</option>
                <option value='972'> - - Muut tarvikkeet</option>
                <option value='503'> - Matkapuhelimet, muut merkit</option>
                <option value='504'> - - Matkapuhelimet, Motorola</option>
                <option value='507'> - - Matkapuhelimet, Siemens</option>
                <option value='509'> - - Matkapuhelimet, muut merkit</option>
                <option value='973'> - - Tarvikkeet, muut merkit</option>
                <option value='513'> - Muut puhelimet</option>
                <option value='514'> - - Lankapuhelimet ja langattomat</option>
                <option value='515'> - - Puhelinvastaajat</option>
                <option value='516'> - Muuta</option>
                <option value='517'> - - Hakulaitteet</option>
                <option value='512'> - - Matkapuhelinten kuoret</option>
                <option value='518'> - - Puhelinosakkeet ja -liittymät</option>
                <option value='1122'> - Älykellot</option>
                <option value='1146'> - - Mobvoi</option>
                <option value='1144'> - - Ksix</option>
                <option value='1143'> - - iW</option>
                <option value='1142'> - - Brigmton</option>
                <option value='1141'> - - Acme</option>
                <option value='1140'> - - STREETZ</option>
                <option value='1139'> - - Makibes</option>
                <option value='1138'> - - MTP Products</option>
                <option value='1137'> - - Denver</option>
                <option value='1136'> - - Xiaomi</option>
                <option value='1135'> - - Nokia</option>
                <option value='1134'> - - Forever</option>
                <option value='1132'> - - Huawei</option>
                <option value='1130'> - - eStrore</option>
                <option value='1129'> - - Samsung</option>
                <option value='1128'> - - Garmin</option>
                <option value='1127'> - - Muut merkit</option>
                <option value='397'>Pääsyliput</option>
                <option value='398'> - Pääsyliput</option>
                <option value='399'> - - Konserttiliput</option>
                <option value='402'> - - Urheilutapahtumat</option>
                <option value='401'> - - Muut liput</option>
                <option value='519'>Rakentaminen</option>
                <option value='520'> - Rakentaminen</option>
                <option value='521'> - - Koneet</option>
                <option value='523'> - - Rakennustarvikkeet ja -materiaalit</option>
                <option value='1077'> - - Sähkötarvikkeet</option>
                <option value='1075'> - - Tontit</option>
                <option value='524'> - - Työkalut ja laitteet</option>
                <option value='522'> - - Muut rakennus- ja remonttitarvikkeet</option>
                <option value='525'>Sekalaista</option>
                <option value='526'> - Sekalaista</option>
                <option value='908'> - - Lahjakortit ja kupongit</option>
                <option value='527'> - - Muihin osastoihin sopimattomat</option>
                <option value='528'>Taide ja design</option>
                <option value='529'> - Designhuonekalut ja -sisustus</option>
                <option value='530'> - - Designhuonekalut 1960-1980-luvut</option>
                <option value='531'> - - Designhuonekalut 1990-</option>
                <option value='532'> - Lasi</option>
                <option value='533'> - - Suomalainen designlasi, ei-signeerattu</option>
                <option value='534'> - - Suomalainen designlasi, signeerattu</option>
                <option value='535'> - - Suomalainen koristelasi</option>
                <option value='536'> - - Suomalainen käyttölasi</option>
                <option value='537'> - - Tunnistamaton ja muu lasi</option>
                <option value='538'> - - Ulkomainen lasi</option>
                <option value='546'> - Posliini ja keramiikka</option>
                <option value='547'> - - Arabian kahvi- ja teeastiastot</option>
                <option value='548'> - - Arabian maljakot ja koriste-esineet</option>
                <option value='549'> - - Arabian ruoka-astiastot</option>
                <option value='550'> - - Designkeramiikka</option>
                <option value='551'> - - Figuurit</option>
                <option value='552'> - - Kupittaan savi</option>
                <option value='554'> - - Seinälautaset ja -laatat</option>
                <option value='555'> - - Ulkomainen posliini</option>
                <option value='553'> - - Muu posliini</option>
                <option value='38'> - Taide</option>
                <option value='41'> - - Suomalainen grafiikka</option>
                <option value='42'> - - Suomalaiset akvarellit</option>
                <option value='43'> - - Suomalaiset öljyvärimaalaukset</option>
                <option value='44'> - - Ulkomaiset öljyvärimaalaukset</option>
                <option value='45'> - - Veistokset</option>
                <option value='39'> - - Muu suomalainen taide</option>
                <option value='40'> - - Muu ulkomainen taide</option>
                <option value='539'> - Muut</option>
                <option value='541'> - - Hopea</option>
                <option value='544'> - - Puu- ja metalliesineet</option>
                <option value='318'> - - Taidekirjat</option>
                <option value='543'> - - Muu taideteollisuus</option>
                <option value='51'>Tietotekniikka</option>
                <option value='52'> - Komponentit ja oheislaitteet</option>
                <option value='53'> - - CD-, DVD- ja levykeasemat</option>
                <option value='56'> - - Emolevyt</option>
                <option value='897'> - - Kannettavien tietokoneiden akut ja tarvikkeet</option>
                <option value='57'> - - Kotelot ja virtalähteet</option>
                <option value='58'> - - Kovalevyt</option>
                <option value='60'> - - Muistikortit</option>
                <option value='61'> - - Muistipiirit</option>
                <option value='63'> - - Näppäimistöt ja hiiret</option>
                <option value='64'> - - Näytönohjainkortit</option>
                <option value='59'> - - Näytöt</option>
                <option value='66'> - - Prosessorit ja tuulettimet</option>
                <option value='67'> - - Skannerit</option>
                <option value='73'> - - Tablettien oheislaitteet ja tarvikkeet</option>
                <option value='68'> - - Tulostimet</option>
                <option value='69'> - - Tyhjät CD- ja DVD-levyt, muut tallennettavat mediat</option>
                <option value='70'> - - Verkkotuotteet ja modeemit</option>
                <option value='71'> - - Äänikortit ja kaiuttimet</option>
                <option value='62'> - - Muut tietokonelaitteet ja -tarvikkeet</option>
                <option value='79'> - Ohjelmistot</option>
                <option value='80'> - - Hyötyohjelmat</option>
                <option value='81'> - - Käyttöjärjestelmät</option>
                <option value='82'> - - Linux-käyttöjärjestelmät ja ohjelmat</option>
                <option value='83'> - Tietokoneet</option>
                <option value='84'> - - Kannettavat tietokoneet</option>
                <option value='85'> - - Pöytätietokoneet</option>
                <option value='1078'> - - Tabletit</option>
                <option value='72'> - Muu tietotekniikka</option>
                <option value='560'> - - Laskimet</option>
                <option value='65'> - - Peliohjaimet</option>
                <option value='1112'> - - Retrokoneet ja oheislaitteet</option>
                <option value='559'> - - Toimistotekniikka</option>
                <option value='76'> - Muuta tietokoneaiheista</option>
                <option value='78'> - - Muuta tietokoneisiin liittyvää</option>
                <option value='561'>Vaatetus</option>
                <option value='570'> - Miestenvaatteet</option>
                <option value='571'> - - Alusvaatteet, yö- ja uima-asut</option>
                <option value='1056'> - - Hatut ja pipot</option>
                <option value='572'> - - Housut ja shortsit</option>
                <option value='573'> - - Kengät</option>
                <option value='752'> - - Paidat</option>
                <option value='753'> - - Puserot ja neuleet</option>
                <option value='576'> - - Puvut ja muut juhlavaatteet</option>
                <option value='1058'> - - Solmiot ja kaulahuivit</option>
                <option value='577'> - - Takit</option>
                <option value='578'> - - Ulkoilu- ja urheiluvaatteet</option>
                <option value='754'> - - Muut asusteet</option>
                <option value='574'> - - Muut miestenvaatteet</option>
                <option value='1113'> - - Vaatepaketit</option>
                <option value='582'> - Naistenvaatteet</option>
                <option value='583'> - - Alusvaatteet, yöpaidat ja uima-asut</option>
                <option value='764'> - - Avokkaat ja sandaalit</option>
                <option value='761'> - - Bleiserit, jakut ja liivit</option>
                <option value='757'> - - Farkut</option>
                <option value='584'> - - Goottivaatteet</option>
                <option value='755'> - - Hameet</option>
                <option value='1057'> - - Hatut, pipot, päähineet</option>
                <option value='586'> - - Housut ja shortsit</option>
                <option value='767'> - - Huivit ja käsineet</option>
                <option value='587'> - - Hääpuvut</option>
                <option value='588'> - - Iltapuvut ja juhla-asut</option>
                <option value='763'> - - Korkokengät</option>
                <option value='756'> - - Mekot</option>
                <option value='766'> - - Muut naisten kengät</option>
                <option value='591'> - - Muut naistenvaatteet</option>
                <option value='758'> - - Paidat</option>
                <option value='759'> - - Puserot ja neuleet</option>
                <option value='760'> - - Päällystakit</option>
                <option value='765'> - - Saappaat ja nilkkurit</option>
                <option value='762'> - - Topit</option>
                <option value='595'> - - Ulkoilu- ja urheiluvaatteet</option>
                <option value='596'> - - Äitiysvaatteet</option>
                <option value='769'> - - Vaatepaketit</option>
                <option value='597'> - Nuortenvaatteet 146-170 cm</option>
                <option value='715'> - - Poikien paidat ja puserot</option>
                <option value='716'> - - Poikien housut ja shortsit</option>
                <option value='717'> - - Poikien ulkovaatteet</option>
                <option value='718'> - - Poikien asusteet</option>
                <option value='719'> - - Muut poikien vaatteet</option>
                <option value='599'> - - Poikien kengät</option>
                <option value='720'> - - Poikien vaatepaketit</option>
                <option value='721'> - - Tyttöjen paidat ja puserot</option>
                <option value='722'> - - Tyttöjen housut ja shortsit</option>
                <option value='723'> - - Tyttöjen hameet ja mekot</option>
                <option value='724'> - - Tyttöjen ulkovaatteet</option>
                <option value='725'> - - Tyttöjen asusteet</option>
                <option value='726'> - - Muut tyttöjen vaatteet</option>
                <option value='601'> - - Tyttöjen kengät</option>
                <option value='727'> - - Tyttöjen vaatepaketit</option>
                <option value='598'> - - Muu nuorisomuoti</option>
                <option value='562'> - Lastenvaatteet 86-140 cm</option>
                <option value='579'> - Asusteet</option>
                <option value='911'> - - Aurinkolasit ja silmälasit</option>
                <option value='702'> - - Vyöt</option>
                <option value='610'>K-18</option>
                <option value='611'> - Elokuvat Blu-Ray</option>
                <option value='612'> - - Elokuvapaketit</option>
                <option value='613'> - - Gay</option>
                <option value='614'> - - Hetero</option>
                <option value='615'> - - Kotimaiset</option>
                <option value='616'> - - Lesbo</option>
                <option value='618'> - - Parisuhde</option>
                <option value='617'> - - Blu-ray - Muut</option>
                <option value='619'> - Elokuvat DVD</option>
                <option value='620'> - - Elokuvapaketit</option>
                <option value='621'> - - Gay</option>
                <option value='622'> - - DVD - Hetero</option>
                <option value='623'> - - Kotimaiset</option>
                <option value='624'> - - Lesbo</option>
                <option value='626'> - - Parisuhde</option>
                <option value='625'> - - DVD - Muut</option>
                <option value='627'> - Elokuvat Video</option>
                <option value='628'> - - Elokuvapaketit</option>
                <option value='629'> - - Gay</option>
                <option value='630'> - - Hetero</option>
                <option value='631'> - - Kotimaiset</option>
                <option value='632'> - - Lesbo</option>
                <option value='634'> - - Parisuhde</option>
                <option value='633'> - - Video - Muut</option>
                <option value='635'> - K-18</option>
                <option value='636'> - - Lehdet ja kirjat</option>
                <option value='637'> - - Vaatteet</option>
                <option value='638'> - - Välineet</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Price suggestions</label>
          <div class="control">
            <price-suggestions v-bind:search-object="this.game" v-on:price="updatePrice"></price-suggestions>
          </div>
        </div>

        <div class="field">
          <label class="label">Price (EUR)</label>
          <div class="control">
            <input class="input" type="number" v-model="price" placeholder="4.90" step="0.1" min="0.1" >
          </div>
        </div>

        <div class="field">
          <label class="label">Condition</label>
          <div class="control">
            <div class="select">
              <select v-model="condition">
                <option value="new">New</option>
                <option value="like-new">Like new</option>
                <option value="good">Good</option>
                <option value="acceptable">Acceptable</option>
                <option value="weak">Weak</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Description</label>
          <div class="control">
            <textarea class="textarea" v-model="description"  placeholder="Description text..."></textarea>
          </div>
        </div>

        <div class="field">
          <label class="label">Huutonet ID (will override all other fields)</label>
          <div class="control">
            <input class="input" type="text" v-model="huutonetId">
          </div>
        </div>

        <div class="field">
          <div class="control">
            <button class="button is-primary" v-on:click.prevent="submit">Submit</button>&nbsp;
            <button class="button is-warning" v-on:click.prevent="goToNextPage">Skip</button>
          </div>
        </div>

        <p class="content">Ids in queue: {{ idList.length - 1 }}</p>

        <div v-if="submitting" class="notification is-warning">
          Submitting listing, please wait...
        </div>

      </form>

    </div>

  </div>
</template>

<script>
import { sellerApi } from '@/sellerApi';
import PriceSuggestions from "@/components/PriceSuggestions";

import { productDescriptions } from '@/productDescriptions';

export default {
  name: 'ListHuutonet',
  components: {
    PriceSuggestions
  },
  data: function() {
    return {
      game: false,
      title: "",
      price: 4.90,
      condition: "good",
      category: 448,
      submitting: false,
      huutonetId: "",
      activeId: 0,
      idList: [],
      sellerApi
    }
  },
  mounted: function() {
    this.idList = this.$route.params.id.split(",");
    this.activeId = this.idList[0];
    this.loadData();
  },
  methods: {
    loadData: function() {
      var component = this;

      sellerApi.client.get("stock/" + component.activeId)
          .then(function(response) {
            component.game = response.data;
            if(component.game.title.length > 60) {
              component.title = component.game.title.substring(0, 60);
            } else {
              component.title = component.game.title + " - " + component.game.platform + (component.game.region !== "REGION_FREE" ? " (" + component.game.region + ")" : "" );
              if(component.title.length > 60) {
                component.title = component.title.substring(0, 60);
              }
            }
            component.price = component.game.price;

            var description = component.game.description;
            productDescriptions.forEach(desc => {
              description = description.replace(desc.en, desc.fi);
            });

            component.description = description;

            switch(component.game.condition) {
              case 'New':
                component.condition = "new";
                break;
              case 'Good':
              case 'Very good':
                component.condition = "good";
                break;
              case 'Acceptable':
                component.condition = "acceptable";
                break;
            }
            switch(component.game.platform) {
              case 'Playstation':
                component.category = 463;
                break;
              case 'Playstation 2':
                component.category = 459;
                break;
              case 'Playstation 3':
                component.category = 461;
                break;
              case 'Playstation 4':
                component.category = 1103;
                break;
              case 'Playstation 5':
                component.category = 1148;
                break;
              case 'Xbox':
                component.category = 469;
                break;
              case 'Xbox 360':
                component.category = 467;
                break;
              case 'Xbox One':
                component.category = 1107;
                break;
              case 'Wii':
                component.category = 455;
                break;
              case 'Wii U':
                component.category = 1105;
                break;
              case 'NES':
                component.category = 457;
                break;
              case 'Super Nintendo':
                component.category = 457;
                break;
              case 'Nintendo Switch':
                component.category = 457;
                break;
              case 'Nintendo DS':
                component.category = 476;
                break;
              case 'GameBoy':
                component.category = 472;
                break;
              case 'GameBoy Advance':
                component.category = 472;
                break;
              case 'PSP':
                component.category = 478;
                break;
              case 'PC':
                component.category = 496; // Muut PC-pelit
                break;
              default:
                component.category = 452; // Muut
                break;
            }
          })
          .catch(function(error) {
            alert(error);
          });
    },
    submit: function() {
      var component = this;
      this.submitting = true;
      sellerApi.client.post("list/huutonet", {
        id: this.game.id,
        title: this.title,
        category: this.category,
        price: this.price,
        condition: this.condition,
        description: this.description,
        huutonet_id: this.huutonetId
      })
          .then(function(response) {
            component.submitting = false;
            if(response.data.status === "ok") {
              component.goToNextPage();
            }
          })
          .catch(function(error) {
            component.submitting = false;
            alert(error);
          });
    },
    updatePrice: function(usdPrice) {
      this.price = Math.round(usdPrice / 1.2) + 0.90;
    },
    goToNextPage: function() {
      // Get next id in queue
      this.idList = this.idList.slice(1);
      if(this.idList.length === 0) {
        this.$router.push("/stock");
      } else {
        // Update url (only history)
        this.$router.push("/list/huutonet/" + this.idList.join(","));
        window.scrollTo({top: 0, left: 0});
        this.activeId = this.idList[0];
        this.huutonetId = "";
        this.loadData();
      }
    }
  }
}
</script>

<style lang="scss">

.page-list-huutonet {
  .well .image {
    max-width: 15rem;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    vertical-align: top;
  }
}

</style>
