export const productDescriptions = [
    {
        en: 'Includes manual',
        fi: 'Sisältää ohjekirjan (pelin lisäksi)'
    }, {
        en: 'No manual',
        fi: 'Ei sisällä ohjekirjaa'
    }, {
        en: 'Like new',
        fi: 'Kuin uusi'
    }, {
        en: 'Scandinavian version',
        fi: 'Pohjoismainen versio'
    }, {
        en: 'UK version',
        fi: 'UK-versio'
    }, {
        en: 'Sticker on front',
        fi: 'Tarra etukannessa'
    }, {
        en: 'Sticker on back',
        fi: 'Tarra takakannessa'
    }, {
        en: 'Platinum release',
        fi: 'Platinum-julkaisu'
    }, {
        en: 'Game of the Year release',
        fi: 'Game of the Year-julkaisu'
    },

];
