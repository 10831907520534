<template>
  <div class="page-listings">

    <div class="section container">
      <p class="content">Missing prices: <strong>{{ missingPrices.length}}</strong></p>
      <p class="content">Missing eBay listings: <strong>{{ missingEbay.length}}</strong> <router-link v-bind:to="ebayUrl" class="button is-primary is-small">Input eBay Listings</router-link></p>
      <p class="content"><a v-bind:href="sellerApi.baseUrl + 'stock/export_unlisted_ebay'" class="button is-small">Export unlisted stock for eBay</a></p>
      <p class="content"><input type="file" ref="ebayReportUpload" class="input is-inline is-small" accept=".csv"> <button class="button is-small" v-on:click.prevent="uploadEbayReport">Upload eBay report</button></p>
      <p class="content">Missing Huutonet listings: <strong>{{ missingHuutonet.length}}</strong> <router-link v-bind:to="huutonetUrl" class="button is-primary is-small">Input Huutonet Listings</router-link></p>
      <p class="content">Missing Retroplace listings: <strong>{{ missingRetroplace.length}}</strong> <router-link v-bind:to="retroplaceUrl" class="button is-primary is-small">Input Retroplace Listings</router-link></p>
      <p class="content"><a v-bind:href="sellerApi.baseUrl + 'stock/export_shopify'" class="button is-small">Export to Shopify</a></p>

      <h2 class="title is-4">Orphaned listings</h2>

      <p class="content">

        <select v-model="orphanType" class="input is-inline is-small">
          <option value="all">- All types -</option>
          <option value="ebay">Ebay</option>
          <option value="huutonet">Huutonet</option>
          <option value="retroplace">Retroplace</option>
        </select>

        <button v-on:click.prevent="fetchOrphans" class="button is-inline is-small is-primary">Fetch orphans</button>

      </p>

      <p v-for="orphan in orphans" v-bind:key="orphan.id" class="content">
        <a v-bind:href="buildOrphanUrl(orphan)" target="_blank">{{ orphan.game.id }} - {{ orphan.game.title }} ({{ orphan.type }} {{ orphan.external_id }})</a>&nbsp;&nbsp;
        <button class="button is-small is-danger" v-on:click.prevent="deleteListing(orphan)">Delete listing</button>
      </p>

    </div>

  </div>
</template>

<script>

import { sellerApi } from '@/sellerApi';

export default {
  name: 'Stock',
  data: function() {
    return {
      sellerApi: sellerApi,
      total: 0,
      missingPrices: [],
      missingEbay: [],
      missingHuutonet: [],
      missingRetroplace: [],
      ebayUrl: "",
      huutonetUrl: "",
      retroplaceUrl: "",
      orphanType: "all",
      orphans: []
    }
  },
  mounted: function() {
    var component = this;
    sellerApi.client.get("stock/missing")
        .then(response => {
          component.total = response.data.total;
          component.missingPrices = response.data.missing_prices;
          component.missingEbay = response.data.missing_ebay;
          component.missingHuutonet = response.data.missing_huutonet;
          component.missingRetroplace = response.data.missing_retroplace;

          component.ebayUrl = "/list/ebay/" + component.missingEbay.join(",");
          component.huutonetUrl = "/list/huutonet/" + component.missingHuutonet.join(",");
          component.retroplaceUrl = "/list/retroplace/" + component.missingRetroplace.join(",");

        });
  },
  methods: {
    uploadEbayReport: function () {

      var component = this;

      if(this.$refs.ebayReportUpload.files[0] !== undefined) {

        let formData = new FormData();
        formData.append('csv', this.$refs.ebayReportUpload.files[0]);

        sellerApi.client.post(
            "list/ebay_upload_results",
            formData,
            {headers: {"Content-Type": "multipart/form-data"}}
        )
        .then(response => {

          if(response.data.status === "ok") {
            component.$router.push("/stock");
          } else {
            alert("eBay report upload failed");
          }

        });

      }

    },
    fetchOrphans: function() {

      var component = this;

      component.orphans = [];

      sellerApi.client.get("list/orphans/" + component.orphanType)
      .then(response => {
        if(response.data.status === "ok") {
          component.orphans = response.data.listings;
        } else {
          alert("Fetching orphans failed");
        }
      });

    },
    buildOrphanUrl: function(listing) {

      switch(listing.type) {
        case 'ebay':
          return "https://www.ebay.com/sh/lst/active?catType=storeCategories&q_field1=listingId&q_op1=EQUAL&q_value1=" + listing.external_id + "&action=search";
        case 'huutonet':
          return "https://huuto.net/kohteet/game/" + listing.external_id;
        case 'retroplace':
          return "https://www.retroplace.com/en/games/" + listing.external_id;
      }

    },
    deleteListing: function(listing) {

      var component = this;

      sellerApi.client.post("list/" + listing.id + "/delete")
          .then(response => {
            if(response.data.status === "ok") {
              component.orphans = component.orphans.filter(aListing => aListing.id !== listing.id);
            } else {
              alert("Deleting listing failed");
            }
          });

    }
  }
}
</script>

<style lang="scss">

</style>
