<template>
  <div id="price-suggestions-container">
    <button class="button is-primary is-outlined" v-on:click.prevent="fetchPriceSuggestions">Fetch price suggestions</button>
    <div class="box" v-if="priceSuggestions.length">
      <p v-for="price in priceSuggestions" v-bind:key="price.id">{{ price.name }} ({{ price.console}}) <button class="button is-danger is-small has-text-weight-bold" v-on:click.prevent="sendPrice(price.price_loose)">{{ price.price_loose }}$</button> | <button class="button is-warning is-small has-text-weight-bold" v-on:click.prevent="sendPrice(price.price_cib)">{{ price.price_cib }}$</button> | <button class="button is-success is-small has-text-weight-bold" v-on:click.prevent="sendPrice(price.price_new)">{{ price.price_new }}$</button>
      </p>
    </div>
  </div>
</template>

<script>

import {sellerApi} from "@/sellerApi";

export default {
  name: 'PriceSuggestions',
  props: [
      'searchObject'
  ],
  data: function() {
    return {
      priceSuggestions: []
    }
  },
  methods: {
    fetchPriceSuggestions: function() {
      var component = this;
      component.priceSuggestions = [];

      var searchString = this.searchObject;

      if (typeof this.searchObject !== 'string') {
        searchString = this.searchObject.title + " " + this.searchObject.platform + " " + this.searchObject.region;
      }

      sellerApi.client.get("search/string/" + encodeURIComponent(searchString))
          .then(function (response) {
            if (response.data.status === "ok") {
              component.priceSuggestions = response.data.priceCharting;
            } else {
              alert("No price suggestions");
            }
          })
          .catch(function (error) {
            alert(error);
          });
    },
    sendPrice: function(price) {
      this.$emit('price', price);
    }
  }
}

</script>
