<template>
  <div class="page-view-deal">

    <p class="content"><router-link to="/deals" class="button is-outlined is-primary">Back to deals</router-link></p>

    <div v-if="deal">

      <h1 class="title is-4">{{ deal.title }}</h1>

      <p class="content">Deal status: <strong>{{ formatStatus(deal.status) }}</strong></p>
      <p class="content">
        <button class="button is-outlined is-info" v-on:click.prevent="setStatus(1)">Set status to "Active"</button>&nbsp;
        <button class="button is-outlined is-danger" v-on:click.prevent="setStatus(0)">Set status to "Inactive"</button>&nbsp;
      </p>

      <form v-on:submit.prevent="saveChanges">

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            {{ deal.id }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Type</label>
          </div>
          <div class="field-body">
            {{ deal.type }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">External ID</label>
          </div>
          <div class="field-body">
            {{ deal.external_id }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Title</label>
          </div>
          <div class="field-body">
            <a v-bind:href="formatListingUrl(deal)" target="_blank">{{ deal.title }}</a>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Price</label>
          </div>
          <div class="field-body">
            {{ deal.price }}
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Open</label>
          </div>
          <div class="field-body">
            {{ deal.open }}
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Close</label>
          </div>
          <div class="field-body">
            {{ deal.close }}
          </div>
        </div>

        <div class="field">
          <label class="label">Search prices</label>
          <div class="control">
            <input type="text" v-model="search" class="input"><br/>
            <button class="button is-primary" v-on:click.prevent="fetchPriceSuggestions">Fetch price suggestions</button><br/>
            <div class="box" v-if="priceSuggestions.length">
              <p v-for="price in priceSuggestions" v-bind:key="price.id">
                <button class="button is-small" v-on:click.prevent="choosePriceSuggestion(price)">{{ price.name }} ({{ price.console}}) - {{ price.price_loose }}$ - {{ price.price_cib }}$ - {{ price.price_new }}$</button>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">PC Title</label>
          </div>
          <div class="field-body">
            <input type="text" v-model="pricecharting_title" class="input">
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">PC Loose</label>
          </div>
          <div class="field-body">
            <input type="text" v-model="pricecharting_loose" class="input">
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">PC CIB</label>
          </div>
          <div class="field-body">
            <input type="text" v-model="pricecharting_cib" class="input">
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">PC New</label>
          </div>
          <div class="field-body">
            <input type="text" v-model="pricecharting_new" class="input">
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label"></label>
          </div>
          <div class="field-body">
            <input type="submit" value="Save" class="button is-primary">
          </div>
        </div>

      </form>

      <br/>
      <br/>

    </div>

  </div>
</template>

<script>

import { sellerApi } from '@/sellerApi';

export default {
  name: 'ViewDeal',
  data: function() {
    return {
      deal: false,
      pricecharting_title: "",
      pricecharting_loose: "",
      pricecharting_cib: "",
      pricecharting_new: "",
      search: "",
      priceSuggestions: []
    }
  },
  mounted: function() {
    var component = this;

    sellerApi.client.get("deal/" + this.$route.params.id)
        .then(function(response) {
          component.deal = response.data;
          component.pricecharting_title = component.deal.pricecharting_title;
          component.pricecharting_loose = component.deal.pricecharting_loose;
          component.pricecharting_cib = component.deal.pricecharting_cib;
          component.pricecharting_new = component.deal.pricecharting_new;
          component.search = component.deal.title;
        })
        .catch(function(error) {
          alert(error);
        });
  },
  methods: {
    formatStatus: function(status) {
      switch(status) {
        case 0:
          return 'Inactive';
        case 1:
          return 'Active';
      }
    },
    formatListingUrl: function(listing) {
      switch(listing.type) {
        case 'ebay':
          return "https://www.ebay.com/itm/game/" + listing.external_id;
        case 'huutonet':
          return "https://huuto.net/kohteet/game/" + listing.external_id;
      }
    },
    setStatus: function(status) {
      var component = this;

      sellerApi.client.post("deal/" + this.$route.params.id + "/status/" + status)
          .then(function(response) {
            component.deal = response.data;
          })
          .catch(function(error) {
            alert(error);
          });
    },
    saveChanges: function() {
      var component = this;

      sellerApi.client.post("deal/" + this.$route.params.id + "/edit", {
        pricecharting_title: this.pricecharting_title,
        pricecharting_loose: this.pricecharting_loose,
        pricecharting_cib: this.pricecharting_cib,
        pricecharting_new: this.pricecharting_new,
      })
          .then(function(response) {
            component.deal = response.data.deal;
          })
          .catch(function(error) {
            alert(error);
          });
    },
    fetchPriceSuggestions: function() {
      var component = this;
      component.priceSuggestions = [];
      sellerApi.client.get("search/string/" + this.search)
          .then(function(response) {
            if(response.data.status === "ok") {
              component.priceSuggestions = response.data.priceCharting;
            } else {
              alert("No price suggestions");
            }
          })
          .catch(function(error) {
            alert(error);
          });
    },
    choosePriceSuggestion(price) {
      this.pricecharting_title = price.name + " (" + price.console + ")";
      this.pricecharting_loose = price.price_loose;
      this.pricecharting_cib = price.price_cib;
      this.pricecharting_new = price.price_new;
    }
  }
}
</script>
